<div class="header-menu-nav-mobile-backdrop" [ngClass]="{'active': _showMobileNav}"></div>
<div class="global-header-nav-container" *ngIf="parent_nav" [ngClass]="{'expand': _showMobileNav}">
    <div class="container header-menu-nav-container desktop" #headerMenuNavDesktop>
        <ul class="main-header-nav-list ga4-nav-click header" [ngClass]="{
            'active': _showMobileNav,
            'left': isMainHnlListActiveButHidden
        }">
            <li class="main-hnl-list-item"
                [ngClass]="{
                    'active':
                        navItem.value.active ||
                        activeShownElements[navItem.value.catalog_item_id]
                }"
                *ngFor="let navItem of desktopNav | keyvalue: asIsOrder"
                (click)="setItemAsActiveShown(navItem.value.catalog_item_id)"
                (mouseenter)="removeFocusedElement($event); windowInnerWidth > 991 && navItem.value.active = true"
                (mouseleave)="windowInnerWidth > 991 && navItem.value.active = false"
                data-nav-level="0"
                data-has-children="1"
                [attr.data-nav-title]="navItem.value.catalog_name | utf8Decode | decodeHtml">

                <ng-container *ngIf="navItem.value.url; else nonLink">
                    <a [href]="navItem.value.url"
                       [attr.rel]="navItem.value.rel_attribute ? navLevelFourItem.value.rel_attribute : ''"
                       [attr.target]="navItem.value.new_window_flag == 'y' ? '_blank' : ''"
                       class="main-hnl-url"
                       (click)="processRedirect($event, navItem.value.children)"
                       (mouseenter)="setHeaderNavListAsRightAlign(); setHeightOfMultiLevelHeaderNavList();"
                       (focusin)="setHeaderNavListAsRightAlign(); setHeightOfMultiLevelHeaderNavList(); ">
                        <span [innerHTML]="navItem.value.catalog_name"></span>
                        <span class="hnl-url-icon fehp-arrow-right hidden-md hidden-lg"></span>
                    </a>
                </ng-container>

                <ng-template #nonLink>
                    <span class="main-hnl-url"
                        (mouseenter)="setHeaderNavListAsRightAlign(); setHeightOfMultiLevelHeaderNavList();"
                        (focusin)="setHeaderNavListAsRightAlign(); setHeightOfMultiLevelHeaderNavList(); ">
                        <span [innerHTML]="navItem.value.catalog_name"></span>
                        <span class="hnl-url-icon fehp-arrow-right hidden-md hidden-lg"></span>
                    </span>
                </ng-template>

                <ng-container *ngIf="navItem.value.children">
                    <div class="comp-header-nav-list-container level-1"
                        [attr.data-catalog-item-id]="navItem.value.catalog_item_id"
                        [ngClass]="{
                            'right-align': navItem.value.right_align,
                            'left': isChildrenActiveButHidden
                        }"
                        #headerNavListContainers>

                        <div class="header-nav-list-wrapper-parent">
                            <ul class="header-nav-list hidden-md hidden-lg level-1">
                                <li class="hnl-list-item-parent">
                                    <span class="hnl-link reverse">
                                        <span class="hnl-link-icon reverse fehp-arrow-left" (click)="goToPreviousNavList($event, navItem.value.catalog_item_id)"></span>
                                        <span class="hnl-label" [innerHTML]="navItem.value.catalog_name"></span>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="header-nav-list-wrapper">
                            <header-nav-list
                                *ngFor="let navItemChildren of navItem.value.children | keyvalue: asIsOrder"
                                [isMobile]="isMobile"
                                [allProductsDiscountData]="all_products_discount_data"
                                [level]="1"
                                [navData]="navItemChildren.value.children"
                                [parentNav]="navItem.value.children"
                                [parentNavTitle]="navItem.value.catalog_name"
                                (setGroupAsActiveButHiddenEvent)="setChildrenAsActiveButHidden($event)">
                            </header-nav-list>
                        </div>
                    </div>
                </ng-container>
            </li>
        </ul>
    </div>
</div>
