/**
 *
 * @author Joel Mamuyac<joel.m@digitalroominc.com>
 *
 **/
import {Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
    ComponentFactoryResolver} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {Subscription} from 'rxjs';

// import dynamic components
import {LoginHandlerComponent} from './login-handler.component';
import {RegisterHandlerComponent} from './register-handler.component';
import {ForgotPasswordHandlerComponent} from './forgot-password-handler.component';


// import shippingdirective
import {LoginRegisterHostDirective} from './login-register-host.directive';

@Component({
    selector: 'login-register-host',
    templateUrl: 'login-register-host.component.html',
})
export class LoginRegisterHostComponent implements OnInit, OnDestroy {
    @ViewChild(LoginRegisterHostDirective, {static: true}) loginRegisterHost: LoginRegisterHostDirective;
    @Input() form_type : any = 'login';
    @Input() modal = true;
    @Output() public onFormType = new EventEmitter();

    private componentRef: any;
    private subscription: Subscription;
    private forms_list : any = {
        login: LoginHandlerComponent,
        register: RegisterHandlerComponent,
        forgot_password: ForgotPasswordHandlerComponent,
    };

    private formCsrfTokens = {
        login: '',
        register: '',
        forgot_password: '',
    };


    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private ActivatedRoute: ActivatedRoute,
                private router: Router,
                private ConfigManagerService : ConfigManagerService) {

    }

    ngOnInit() {
        this.switchForm(this.form_type);
    }

    switchForm(form:any) {
        const component = this.forms_list[form];

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        const viewContainerRef = this.loginRegisterHost.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
        // subscribe
        (this.componentRef.instance).onCsrfToken.subscribe((token) => {
            this.formCsrfTokens[form] = token;
        });

        (this.componentRef.instance)['csrfToken'] = this.formCsrfTokens[form];
        (<any> this.componentRef.instance).host = this;

        this.onFormType.emit(form);
    }

    ngOnDestroy() {
        // do nothing
    }
}
