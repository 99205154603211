import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NewsletterService} from '@dri/customer/services/newsletter.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {AuthService} from '@dri/auth/services/auth.service';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {NewsletterComponent} from './newsletter.component';
import {CsrfService} from '@dri/common/services/csrf.service';

@Component({
    selector: 'newsletter-modal',
    templateUrl: 'newsletter-modal.component.html',
})

export class NewsletterModalComponent extends NewsletterComponent {
    constructor(
        protected fb: UntypedFormBuilder,
        protected NewsletterService: NewsletterService,
        protected ConfigManagerService: ConfigManagerService,
        protected CrossStorageClientService: CrossStorageClientService,
        protected AuthService: AuthService,
        protected bsModalRef : BsModalRef,
        protected CsrfService: CsrfService
    ) {
        super(fb, NewsletterService, ConfigManagerService, CrossStorageClientService, AuthService, CsrfService);
    }

    closeModal() {
        this.bsModalRef.hide();
    }
}
