import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpParams,
    HttpEvent
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';

@Injectable({
    providedIn: 'root',
})
export class WebsiteSwitcherInterceptor implements HttpInterceptor {
    constructor(
        private httpClient: HttpClient,
        private ConfigManagerService: ConfigManagerService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(this.appendWebsiteCodeParam(request));
    }

    private appendWebsiteCodeParam(request: HttpRequest<any>) {
        const appConfig = this.ConfigManagerService.getConfig('app');
        const site_code = appConfig.website_code;

        let newParams = new HttpParams({fromString: request.params.toString()});
        newParams = newParams.append('website_code', site_code);

        const req = request.clone({
            params: newParams,
        });

        return req;
    }
}
