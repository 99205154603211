import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {StorageService} from '@dri/common/services/storage.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {LoginRegisterBuilder} from '@dri/dri-login-register';
import {ForgotPasswordService} from '@dri/customer/services/forgot-password.service';
import {CsrfService} from '@dri/common/services/csrf.service';
import {DataLayerService} from '@dri/common/services/datalayer.service';

@Component({
    selector: 'forgot-password-handler',
    templateUrl: 'forgot-password-handler.component.html',
})
export class ForgotPasswordHandlerComponent {
    @Input() host : any;
    @Input() csrfToken: string;
    @Output() onCsrfToken: EventEmitter<string> = new EventEmitter();

    public form_data : any;
    public processing_request = false;
    public forgotPasswordErrorMessages : any = [];
    public success_reset = false;
    public showRegisterForm = false;
    private formName = 'forgot_password';

    constructor(
        private StorageService: StorageService,
        private ConfigManager : ConfigManagerService,
        private ActivatedRoute: ActivatedRoute,
        private Router: Router,
        private ForgotPasswordService : ForgotPasswordService,
        private CsrfService : CsrfService,
        private DataLayerService: DataLayerService,
        @Inject('ForgotPasswordBuilder') protected ForgotPasswordBuilder: LoginRegisterBuilder
    ) {}

    ngOnInit() {
        if (!this.csrfToken) {
            this.CsrfService.getCsrfToken(this.formName).subscribe((res) => {
                if (res['token']) {
                    this.csrfToken = res['token'];
                    this.onCsrfToken.emit(res['token']);
                }
            });
        }

        this.form_data = [
            {
                key: 'email',
                attributes: {
                    name: 'email',
                    type: 'email',
                    placeholder: 'Email',
                    maxlength: 50,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'email': true,
                    'required': true,
                },
                error_message: {
                    'email': 'Invalid Email.',
                    'required': 'Email is required.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
        ];
    }

    resetPassword() {
        if (!this.ForgotPasswordBuilder.checkValidity()) {
            return false;
        }

        // Just in case csrf token ajax is delayed
        if (!this.csrfToken) {
            return false;
        }

        if (!this.CsrfService.validateCsrfToken(this.csrfToken)) {
            this.forgotPasswordErrorMessages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
            return false;
        }


        this.showRegisterForm = false;
        this.processing_request = true;
        this.ForgotPasswordService.sendForgotPasswordRequest(
            this.ForgotPasswordBuilder.form_inputs.email,
            this.csrfToken,
            this.formName
        ).subscribe(
            (response) => {
                this.DataLayerService.logDataLayerEvent({
                    'event': 'request_password',
                    'customer_id': response['customer_id'],
                    'is_success': 'yes',
                });
                this.success_reset = true;
                this.processing_request = false;
            },
            (HttpErrorResponse) => {
                this.DataLayerService.logDataLayerEvent({
                    'event': 'request_password',
                    'customer_id': null,
                    'is_success': 'no',
                });
                this.success_reset = false;
                this.processing_request = false;
                this.showRegisterForm = false;
                if (HttpErrorResponse.error.detail && HttpErrorResponse.error.detail.hasOwnProperty('csrf')) {
                    this.forgotPasswordErrorMessages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
                } else {
                    this.forgotPasswordErrorMessages = ['We are unable to find an account with this email. Please make sure your email is spelled correctly and try again.'];
                }
            }
        );
    }

    switchForm(form) {
        this.host.switchForm(form);
    }
}
