import {
    Component,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {DataLayerService} from '@dri/common/services/datalayer.service';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class NotFoundComponent implements OnInit {
    public appConfig:any;

    constructor(
        protected DataLayerService: DataLayerService,
        protected ConfigManagerService: ConfigManagerService
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
    }

    ngOnInit() {
        this.DataLayerService.logDataLayerEvent({
            event: 'error_event',
            error_type: '404',
        });
    }
}
