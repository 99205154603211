<div class="modal-body">
	<button aria-label="Close" class="close pull-right" type="button" (click)="closeModal()">
		<span aria-hidden="true">
		×
		</span>
	</button>
    <div class="newsletter-contents">
        <div class="subscribe-header-container">
            <div class="subscribe-header">I Like Deals!</div>
            <p class="subscribe-info">Put me on Gary's list for the best deals and new product info.</p>
        </div>
        <div  class="newsletter-form-wrapper">
            <div class="success-message-container" 
            *ngIf="subscriptionSuccess || unsubscribedFlag == 'n'">
            	<span class="success-message alert alert-success">
            		Thank you for subscribing!
            	</span>
        	</div>
            <div *ngIf="showServerValidationMessage">
                <div class="alert alert-danger">
                    <i class="fa fa-warning"></i>Invalid Email.
                </div>
            </div>
            <form id="newsletter_form" role="form" novalidate
                  [formGroup]="newsletter_form" #formNewsletterForm="ngForm"
                  autocomplete="off" (ngSubmit)="subscribe()"  *ngIf="!subscriptionSuccess && unsubscribedFlag == 'y'">
                <div class="input-field site-form-group">
                    <input type="text" class="site-form-input form-control" formControlName="email" id="email" autocomplete="off" no-whitespace maxlength="50" placeholder="Enter your email">
                    <input type="hidden" name="csrf" value="">
                    <p class="error-msg-subscribe hidden-alert-message"
                       *ngIf="showControlError('email')">
                       Invalid Email
                    </p>
                </div>
                <div class="site-form-group has-error" *ngIf="showCsrfError">
                    <div class="input-error-container">
                        <span class="input-error-message">This session has expired. Please refresh and try again.</span>
                    </div>
                </div>  
                <div class="site-form-group form-group" *ngIf="isCaptchaEnabled">
                    <re-captcha id="modal_captcha"
                                [siteKey]="googleRecaptcha.site_key"
                                (resolved)="onCaptchaResponse($event)"
                                [(ngModel)]="captchaModel"
                                [ngModelOptions]="{standalone: true}"
                    ></re-captcha>
                    <div class="input-error-container">
                        <div class="captcha-error-message" *ngIf="showCaptchaError">{{captchaError}}</div>
                    </div>
                </div>  
                <div class="action-btn-container">
                    <button type="submit" class="action-btn newsletter-submit-btn" data-qaid="newsletterSubscribe" [disabled]="!enableSubmitBtn" [ngClass]="{'sending' : processingRequest }">
                        <ng-container *ngIf="processingRequest">
                            <i class="fa fa-circle-o-notch fa-spin"></i>
                        </ng-container>
                        {{!processingRequest ? 'SIGN UP' : 'SENDING SUBSCRIPTION'}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>