<div class="comp-shopping-cart-icon-container">
    <a [routerLink]="[poCartRoute]" *ngIf="!isDesignToolPage; else designCartLink">
        <span class="cart-count" *ngIf="po_cart_count">
            {{po_cart_count}}
        </span>
        <img src="assets/img/cart-blue-icon.svg" alt="Cart" width="100%" height="auto">
        <span class="cart-label">Cart</span>
    </a>
    <ng-template #designCartLink>
        <a href="{{poCartRoute}}">
            <span class="cart-count" *ngIf="po_cart_count">
                {{po_cart_count}}
            </span>
            <img src="assets/img/cart-blue-icon.svg" alt="Cart" width="100%" height="auto">
            <span class="cart-label">Cart</span>
        </a>
    </ng-template>
</div>
