<div class="comp-register-handler-container">
	<div class="heading-container">
        <h2 class="header-title">First time here?</h2>
        <h5>Create an account to get updates on your order.</h5>
    </div>
	<div class="error-messages-container">
		<app-error-messages [error_messages]='registerErrorMessages'></app-error-messages>
	</div>
	<form (ngSubmit)="register()"
	      novalidate
	      autocomplete="off"
	      registerForm [form_data]="form_data">
		<div class="form-group">
			<div class="btn-container">
				<button type="submit"
				        class="site-btn-primary register-submit-btn btn-lg"
				        data-qaid="registerSubmitButton"
				        focus-out
				        [disabled]="processing_request">
				    <span *ngIf="!processing_request; else processing">CREATE MY ACCOUNT</span>
				    <ng-template #processing>
				        <span>
				           	<i class="fa fa-spinner fa-spin"></i> Creating Account...
				        </span>
				    </ng-template>
				</button>
			</div>
		</div>
		<div class="form-group">
			<div class="notes">
            <p>*We may use your phone number to contact you regarding any issues with your orders.</p>
            <p class="terms-and-policy">
                By creating an account, you agree to {{siteName}}'s
                <a data-qaid="termsLink" (click)="openPopUp(termsUrl)" id="linkTerms">Terms of Use</a>
                and
                <a data-qaid="privacyLink" (click)="openPopUp(privacyUrl)" id="linkPolicy">Privacy Policy</a>,
                48HourPrint.com may also send you promotions and discounts.
            </p>
			</div>
		</div>
		<hr>
		<div class="form-group">
			<div class="link-section"><p>Already have an account? <a class="link" (click)="switchForm('login')">Log In</a></p></div>
		</div>
	</form>
</div>
