/**
 *
 */
import {Injectable} from '@angular/core';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';

import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RecentlyViewedProductsService {
    private routesConfig;
    private appConfig;
    private crossStorageConfig;
    public viewed_products_key: any;

    constructor(
        private Http: HttpClient,
        private ConfigManagerService: ConfigManagerService,
        private CrossStorageClientService: CrossStorageClientService
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.routesConfig = this.ConfigManagerService.getConfig('routes');
        this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
        this.viewed_products_key = this.ConfigManagerService.getConfig('storage_keys').viewed_products_key;
    }

    public getPageIds(customerId) {
        const _this = this;
        this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
        return new Promise(function(resolve, reject) {
            _this.getIdsFromStorage().then((data) => {
                let otherParams = '';
                if (data != null) {
                    otherParams = '&recentViewPageIds=' + JSON.parse(JSON.stringify(data));
                }
                const endPoint = _this.appConfig.site_url + _this.routesConfig.personalization +
                    '?customer_id=' + customerId + otherParams;

                _this.Http.get(endPoint).subscribe((response) => {
                    if (response.hasOwnProperty('success') && response['success']) {
                        _this.setIdsToStorage(response['page_ids']);
                    }
                    // return response
                    resolve(response);
                }, (error) => {
                    // return error
                    reject(error);
                });
            });
        });
    }

    public getIdsFromStorage() {
        return this.CrossStorageClientService.getData(this.viewed_products_key);
    }

    public setIdsToStorage(formatIds) {
        this.CrossStorageClientService.setData(this.viewed_products_key, formatIds);
    }
}
