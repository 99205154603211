<div class="comp-newsletter-container" [ngClass]="{'show-flex' : isCustomerDataLoaded}" *ngIf="unsubscribedFlag == 'y'">
    <div class="col-md-10 col-xs-24 newsletter-image-container">
        <div class="unsubscribe-img gary-image" *ngIf="!subscriptionSuccess"></div>
        <div class="subscribe-img gary-image" *ngIf="subscriptionSuccess"></div>
    </div>
    <div class="col-md-14 col-xs-24 newsletter-info-container">
        <div class="info-wrapper" [ngClass]="{'subscribed' : subscriptionSuccess}">
            <div class="subscribe-header-container" *ngIf="!subscriptionSuccess" tabindex="0">
                <div class="subscribe-header">I Like DEALS!</div>
                <p class="subscribe-info">Put me on Gary's list for the best deals and new product info.</p>
            </div>

            <div id="newsletter-subscribed" class="subscribed-header-container" *ngIf="subscriptionSuccess">
                <div class="subscribe-header">You're On GARY's LIST!</div>
                <p class="subscribe-info">Check out the best printing deals from Gary.</p>
            </div>

            <div class="action-btn" *ngIf="subscriptionSuccess">
                <button id="newsletter-subscribed-btn"
                        class="subscribed-success-btn site-btn-secondary"
                        type="button"
                        (click)="redirectToAllProducts()"
                        aria-labelledby="newsletter-subscribed newsletter-subscribed-btn">
                    CLICK HERE
                </button>
            </div>

            <div  class="newsletter-form-wrapper" [ngClass]="{'has-error' : showControlError('email')}" *ngIf="!subscriptionSuccess">
                <div class="error-messages-container" [ngClass]="{'show' : showServerValidationMessage}" style="display: none;">
                    <div class="site-alert-danger error-messages-component">
                        <div class="error-wrap alert-title">
                            <ul class="error-display">
                                <li><i aria-hidden="true" class="icon fehp-warning wrap-icon"></i>
                                    <span class="error title">Invalid Email Address</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="error-messages-container" *ngIf="showCsrfError">
                    <div class="site-alert-danger error-messages-component">
                        <div class="error-wrap alert-title">
                            <span class="error title">This session has expired. Please refresh and try again.</span>
                        </div>
                    </div>
                </div>
                <form class="newsletter-form" role="form" novalidate
                      [formGroup]="newsletter_form" #formNewsletterForm="ngForm"
                      autocomplete="off" (ngSubmit)="showModal()">
                    <div class="site-form-group email-wrapper"
                         [ngClass]="{
                         'has-error' : showControlError('email'),
                         'has-valid-value' : hasValidValue('email'),
                         'has-value' : hasValue('email'),
                         'sending' : processingRequest}">
                        <input type="text" class="site-form-input newsletter-email" formControlName="email" id="email"
                               autocomplete="off" no-whitespace maxlength="50" placeholder="Email Address">
                        <p class="input-error-container" *ngIf="showControlError('email')"
                           [ngClass]="{'show': showControlError('email')}">Invalid Email Address</p>
                    </div>
                    <div class="sign-up-btn-wrapper" [ngClass]="{'sending': processingRequest}">
                        <button type="submit" class="site-btn-primary newsletter-submit-btn" data-qaid="newsletterSubscribe" [disabled]="!enableSubmitBtn" [ngClass]="{'sending' : processingRequest }">
                            <ng-container *ngIf="processingRequest">
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                            </ng-container>
                            {{!processingRequest ? 'SIGN UP' : 'SENDING SUBSCRIPTION'}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div bsModal #successModal="bs-modal" (onHide)="onModalHide($event)" class="site-modal modal fade newsletter-modal"
     role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="modal-close-btn close otg-modal-close-btn" data-dismiss="modal" (click)="successModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <div class="title-badge-wrapper">
                    <h4 class="modal-title">
                        {{showCaptcha ? 'Newsletter Sign Up' : 'You Have Been Successfully Subscribed'}}
                    </h4>
                </div>
            </div>
            <div class="modal-body">
                <div class="error-messages-container" *ngIf="showServerValidationMessage">
                    <div class="site-alert-danger error-messages-component">
                        <div class="error-wrap alert-title">
                            <ul class="error-display">
                                <li><i aria-hidden="true" class="icon fehp-warning wrap-icon"></i>
                                    <span class="error title">Invalid Email Address</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="error-messages-container" *ngIf="showCsrfError">
                    <div class="site-alert-danger error-messages-component">
                        <div class="error-wrap alert-title">
                            <span class="error title">This session has expired. Please refresh and try again.</span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showCaptcha; else successMessage">
                    <div class="show-captcha" *ngIf="showCaptcha">
                        <div class="input-container">
                            <div class="site-form-group form-group">
                                <div class="site-input-group form-group">
                                    <input type="text" class="site-form-input form-control newsletter-email" value="{{newsletterEmail}}" disabled>
                                </div>
                            </div>
                            <div class="site-form-group form-group">
                                <re-captcha id="captcha"
                                            [siteKey]="googleRecaptcha.site_key"
                                            (resolved)="onCaptchaResponse($event)"
                                            [(ngModel)]="captchaModel"
                                ></re-captcha>
                                <div class="input-error-container">
                                    <div class="input-error-message" *ngIf="showCaptchaError">{{captchaError}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="button-container">
                            <button (click)="subscribe()" class="newsletter-submit-btn site-btn-primary"
                                    [disabled]="processingRequest">
                                SIGN ME UP
                                <ng-container *ngIf="processingRequest">
                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #successMessage>
                    <p *ngIf="!showCaptcha">Thank you for subscribing to our email list.</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>