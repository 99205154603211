/**
 * Created by joanna.l on 8/4/2018.
 */
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {StorageService} from '@dri/common/services/storage.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';

@Injectable()
export class CartCountService {
    // Observable string sources
    private cartCountSubject = new Subject<any>();
    // Observable string streams
    cart_count$ = this.cartCountSubject.asObservable();

    private appConfig;
    private checkoutConfig;
    private vid : string;

    constructor(
        private StorageService: StorageService,
        private ConfigManagerService: ConfigManagerService,
        private Http: HttpClient
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.checkoutConfig = this.ConfigManagerService.getConfig('checkout');
        this.vid = this.StorageService.getItem(this.ConfigManagerService.getConfig('storage_keys').visitor_id);
    }

    public setCartCount(cart_count) {
        this.cartCountSubject.next(cart_count);
    }

    public getCartCount(cart_id: string) {
        const _this = this;
        return new Promise(function(resolve, reject) {
            if (cart_id) {
                const endPoint = _this.appConfig.checkout_api_url + _this.checkoutConfig.cart_count_resource + cart_id + '?vid=' + _this.vid;

                // call ajax to get cart count
                _this.Http.get(endPoint).subscribe((response) => {
                    if (response && response['success']) {
                        _this.cartCountSubject.next(response['cart_count']);

                        // return response
                        resolve(response['cart_count']);
                    } else {
                        resolve(0);
                    }
                }, (error) => {
                    // return error
                    resolve(0);
                });
            } else {
                resolve(0);
            }
        });
    }

    public updateCartCountDisplay(cart_id) {
        this.getCartCount(cart_id).then((new_cart_count) => {
            this.cartCountSubject.next(new_cart_count);
        });
    }

    public resetCartCount() {
        this.cartCountSubject.next(0);
    }
}
