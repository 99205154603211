<ul class="header-nav-list ignore-hover level-{{ level }}">
    <li *ngFor="let navItem of navData | keyvalue: asIsOrder"
        class="hnl-list-item level-{{ level }} {{ navItem.value.custom_styles }} {{ additionalClasses }}"
        [ngClass]="{
            'has-children': !checkIfChildrenIsEmpty(navItem.value.children),
            'active': navItem.value.active || activeShownElements[navItem.value.catalog_item_id]
        }"
        (click)="setItemAsActiveShown(navItem.value.catalog_item_id, navItem.value.children)"
        (mouseenter)="removeFocusedElement($event); windowInnerWidth > 991 && navItem.value.active = true"
        (mouseleave)="windowInnerWidth > 991 && navItem.value.active = false"
        [attr.data-nav-level]="level"
        [attr.data-has-children]="!checkIfChildrenIsEmpty(navItem.value.children) ? 1 : 0"
        [attr.data-nav-title]="navItem.value.catalog_name | utf8Decode | decodeHtml"
        [attr.data-parent-title]="parentNavTitle | utf8Decode | decodeHtml">

        <ng-container *ngIf="!navItem.value.url; else actualLink">
            <span class="hnl-link">
                <span class="hnl-label">
                    <span [innerHTML]="navItem.value.catalog_name"></span>

                    <ng-container
                        *ngIf="allProductsDiscountData &&
                            navItem.value.product_id &&
                            navItem.value.custom_styles &&
                            navItem.value.custom_styles.indexOf('has-discount') > -1"><!--
                    -->&nbsp;<!--
                    --><span *ngIf="allProductsDiscountData[navItem.value.product_id]"
                            class="js-product-{{navItem.value.product_id}}-discount-wrapper site-badge-hollow-orange">
                            <span *ngIf="allProductsDiscountData[navItem.value.product_id].discount_percentage"
                                class="js-{{navItem.value.product_id}}-discount-percentage-container">
                                <span class="percentage js-product-{{navItem.value.product_id}}-discount-percentage">
                                    -{{allProductsDiscountData[navItem.value.product_id].discount_percentage}}%
                                </span>
                            </span>

                            <span *ngIf="!allProductsDiscountData[navItem.value.product_id].discount_percentage &&
                                        allProductsDiscountData[navItem.value.product_id].discount_amount"
                                class="js-{{navItem.value.product_id}}-discount-amount-container">

                                <span class="amount js-product-{{navItem.value.product_id}}-discount-amount">
                                    -${{allProductsDiscountData[navItem.value.product_id].discount_amount}}
                                </span>
                            </span>
                        </span>
                    </ng-container>

                    <ng-container *ngIf="navItem.value.custom_styles && navItem.value.custom_styles.indexOf('new-product') > -1"><!--
                    -->&nbsp;<span class="site-badge-green-small">NEW</span><!--
                --></ng-container>

                    <ng-container *ngIf="navItem.value.custom_styles && navItem.value.custom_styles.indexOf('top-seller-product') > -1"><!--
                    -->&nbsp;<span class="site-badge-blue-small">TOP SELLER</span><!--
                --></ng-container>
                </span>

                <span *ngIf="!checkIfChildrenIsEmpty(navItem.value.children)"
                    class="hnl-link-icon fehp-arrow-right">
                </span>
            </span>
        </ng-container>

        <ng-template #actualLink>
            <a [href]="navItem.value.url"
            [attr.rel]="navItem.value.rel_attribute ? navLevelFourItem.value.rel_attribute : ''"
            [attr.target]="navItem.value.new_window_flag == 'y' ? '_blank' : ''"
            class="hnl-link"
            (click)="processRedirect($event, navItem.value.children)">

                <span class="hnl-label">
                    <span [innerHTML]="navItem.value.catalog_name"></span>

                    <ng-container
                        *ngIf="allProductsDiscountData &&
                            navItem.value.product_id &&
                            navItem.value.custom_styles &&
                            navItem.value.custom_styles.indexOf('has-discount') > -1"><!--
                    -->&nbsp;<!--
                    --><span *ngIf="allProductsDiscountData[navItem.value.product_id]"
                            class="js-product-{{navItem.value.product_id}}-discount-wrapper site-badge-hollow-orange">
                            <span *ngIf="allProductsDiscountData[navItem.value.product_id].discount_percentage"
                                class="js-{{navItem.value.product_id}}-discount-percentage-container">
                                <span class="percentage js-product-{{navItem.value.product_id}}-discount-percentage">
                                    -{{allProductsDiscountData[navItem.value.product_id].discount_percentage}}%
                                </span>
                            </span>

                            <span *ngIf="!allProductsDiscountData[navItem.value.product_id].discount_percentage &&
                                        allProductsDiscountData[navItem.value.product_id].discount_amount"
                                class="js-{{navItem.value.product_id}}-discount-amount-container">

                                <span class="amount js-product-{{navItem.value.product_id}}-discount-amount">
                                    -${{allProductsDiscountData[navItem.value.product_id].discount_amount}}
                                </span>
                            </span>
                        </span>
                    </ng-container>

                    <ng-container *ngIf="navItem.value.custom_styles && navItem.value.custom_styles.indexOf('new-product') > -1"><!--
                    -->&nbsp;<span class="site-badge-green-small">NEW</span><!--
                --></ng-container>

                    <ng-container *ngIf="navItem.value.custom_styles && navItem.value.custom_styles.indexOf('top-seller-product') > -1"><!--
                    -->&nbsp;<span class="site-badge-blue-small">TOP SELLER</span><!--
                --></ng-container>
                </span>

                <span *ngIf="!checkIfChildrenIsEmpty(navItem.value.children)"
                    class="hnl-link-icon fehp-arrow-right">
                </span>
            </a>
        </ng-template>

        <ng-container *ngIf="!checkIfChildrenIsEmpty(navItem.value.children)">
            <div class="comp-header-nav-list-container level-{{ level + 1}}" [ngClass]="{
                'left': isChildrenActiveButHidden
            }">
                <div class="header-nav-list-wrapper-parent">
                    <ul class="header-nav-list hidden-md hidden-lg level-{{ level + 1 }}">
                        <li class="hnl-list-item-parent">
                            <span class="hnl-link reverse">
                                <span class="hnl-link-icon reverse fehp-arrow-left" (click)="goToPreviousNavList($event, navItem.value.catalog_item_id)"></span>
                                <span class="hnl-label" [innerHTML]="navItem.value.catalog_name"></span>
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="header-nav-list-wrapper">
                    <header-nav-list
                        *ngFor="let navItemChildren of navItem.value.children | keyvalue: asIsOrder"
                        [isMobile]="isMobile"
                        [allProductsDiscountData]="allProductsDiscountData"
                        [level]="level + 1"
                        [navData]="navItemChildren.value.children"
                        [parentNav]="navItem.value.children"
                        [parentNavTitle]="navItem.value.catalog_name"
                        (setGroupAsActiveButHiddenEvent)="setChildrenAsActiveButHidden($event)">
                    </header-nav-list>
                </div>
            </div>
        </ng-container>
    </li>
</ul>
