import {Directive, OnInit} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';

import {NewsletterModalComponent} from './../components/newsletter-modal.component';
import {HTTPCookieClient} from '@dri/http-cookie-client/src/http-cookie-client';
import {AuthService} from '@dri/auth/services/auth.service';

@Directive({
    selector: '[newsletter-modal-trigger]',
})
export class NewsletterModalTriggerDirective implements OnInit {
    public customerData: any = null;
    public unsubscribedFlag: any = 'y';
    public bsModalRef: BsModalRef;
    public crossStorageConfig: any;
    public HTTPCookie: any;
    public appConfig: any;
    public googleRecaptcha: any;

    constructor(
        protected modalService: BsModalService,
        protected ConfigManagerService: ConfigManagerService,
        private CrossStorageClientService: CrossStorageClientService,
        protected AuthService: AuthService
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.googleRecaptcha = this.ConfigManagerService.getConfig('google_recaptcha');
        this.crossStorageConfig =
            this.ConfigManagerService.getConfig('cross_storage');

        this.HTTPCookie = new HTTPCookieClient({
            cookieDomain: this.appConfig.domain,
            cookieAPI:
                this.appConfig.site_url + this.appConfig.http_cookie_endpoint,
        });
    }

    ngOnInit() {
        if (window.innerWidth > 991) {
            this.CrossStorageClientService.onInit(
                this.crossStorageConfig.hub_url,
                this.crossStorageConfig.hub_opt
            );
            this.getCustomerData();
        }
    }

    showNewsletter(event, _this) {
        let modalClass = 'comp-newsletter-modal-container modal-lg';

        if (this.googleRecaptcha && this.googleRecaptcha.enabled) {
            modalClass += ' with-captcha';
        }

        const modal_options = {
            class: modalClass,
            backdrop: true,
            ignoreBackdropClick: false,
        };

        _this.bsModalRef = _this.modalService.show(
            NewsletterModalComponent,
            modal_options
        );

        _this.HTTPCookie.set('newsletter_popup', true);
    }

    getCustomerData() {
        this.HTTPCookie.get('unsubscribed_flag').then((data) => {
            this.unsubscribedFlag = data == 'n' ? 'n' : 'y';

            const mouseLeaveFunction = (event) => {
                this.showNewsletter(event, this);
                document
                    .querySelector('body')
                    .removeEventListener('mouseleave', mouseLeaveFunction);
            };

            if (this.unsubscribedFlag == 'y') {
                this.HTTPCookie.get('newsletter_popup').then(
                    (newsletter_popup) => {
                        if (!newsletter_popup) {
                            document
                                .querySelector('body')
                                .addEventListener(
                                    'mouseleave',
                                    mouseLeaveFunction
                                );
                        }
                    }
                );
            }

            this.AuthService.logged_in$.subscribe((accessToken) => {
                if (accessToken.customer_data.unsubscribed_flag == 'n') {
                    document
                        .querySelector('body')
                        .removeEventListener('mouseleave', mouseLeaveFunction);
                }
            });
        });
    }
}
