/**
 * Created by joanna.l on 05/07/2019.
 */
import {Injectable} from '@angular/core';
import {PageDiscountDisplayLibrary} from '@dri/page-discount-display-library/src/page-discount-display-library';
import {Subject} from 'rxjs';
import {CouponStorageService} from '@dri/coupon/services/coupon-storage.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';

@Injectable()
export class PageDiscountDisplayService {
    // Observable string sources
    private websiteProductsDiscountDataSubject = new Subject<any>();
    // Observable string streams
    websiteProductsDiscountData$ = this.websiteProductsDiscountDataSubject.asObservable();

    public appConfig;
    public couponStorageKey:any;
    private crossStorageConfig;
    private PageDiscountDisplayLibrary;

    constructor(
        private ConfigManagerService: ConfigManagerService,
        protected CouponStorageService : CouponStorageService
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
        this.couponStorageKey = this.ConfigManagerService.getConfig('coupon').storage_name;
    }

    public setPageDiscountDisplayData(couponCode?) {
        // For displaying discount data based on COUPON FROM LOCAL STORAGE
        if (typeof PageDiscountDisplayLibrary !== 'undefined') {
            const _this = this;
            if (couponCode) {
                _this.getPageDiscountData(couponCode);
            } else {
                const couponFromStorageData = this.CouponStorageService.getCoupon();
                const couponCodeFromStorage = couponFromStorageData &&
                                                typeof couponFromStorageData['pc'] !== 'undefined' &&
                                                typeof couponFromStorageData['pc'][0] !== 'undefined' ?
                    couponFromStorageData['pc'][0] :
                    null;

                _this.getPageDiscountData(couponCodeFromStorage);
            }
        }
    }

    public getPageDiscountData(couponCode) {
        // For setting discount data
        if (typeof PageDiscountDisplayLibrary !== 'undefined') {
            this.PageDiscountDisplayLibrary = new PageDiscountDisplayLibrary();
            const _this = this;

            // let couponConfig = this.ConfigManagerService.getConfig('coupon');
            this.PageDiscountDisplayLibrary.setConfig({
                page_discount_display_url: this.appConfig.checkout_api_url + '/website-products-discount-data',
                website_code: this.appConfig.website_code,
            });

            this.PageDiscountDisplayLibrary.getPageDiscountDisplayData(couponCode).then( function(response) {
                if (response) {
                    _this.websiteProductsDiscountDataSubject.next(_this.PageDiscountDisplayLibrary.allProductsDiscountData);
                }
            });
        }
    }

    public parseJsonString(str: string) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }
}
