import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {HeaderNavLibrary} from '@dri/48hp-header-nav/src/48hp-header-nav-library';
import {Router} from '@angular/router';
import {PageBackdropService} from './../service/page-backdrop.service';
import {HeaderService} from '../service/header.service';
import {PageDiscountDisplayService} from '../service/page-discount-display-service';
import {DomSanitizer} from '@angular/platform-browser';
import {KeyValue} from '@angular/common';

@Component({
    selector: 'app-header-nav',
    templateUrl: 'app-header-nav.component.html',
})
export class AppHeaderNavComponent implements OnInit {
    @Input() enableHeaderNavDataFetching = true;
    public app_config: any;
    private HeaderNavLibrary;
    public desktopNav: any;
    public parent_nav: any;
    public parent_nav_width: any;
    public child_nav: any;
    public all_products_discount_data: any;
    public highestDiscount = 0;
    public showNavFlag = false;
    public _showMobileNav = false;
    private timer: any;
    private tap :any;

    public activeShownElements = {};
    public isMainHnlListActiveButHidden = false;
    public isChildrenActiveButHidden = false;

    @Input() isMobile = false;
    @Input() set showMobileNav(value) {
        this._showMobileNav = value;
        this.showNavFlag = value;
        this.activeShownElements = {};
        this.isMainHnlListActiveButHidden = false;
        this.isChildrenActiveButHidden = false;
    }
    @Output() toggleMenu = new EventEmitter<boolean>();
    @ViewChild('headerMenuNavDesktop') headerMenuNavDesktop;
    @ViewChildren('headerNavListContainers') headerNavListContainers;
    @HostListener('document:focusin', ['$event'])
    onFocusin(e) {
        this.setClassForHeaderNavListItem();
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        const element = event.target || event.srcElement || event.currentTarget;
        this.setClassForHeaderNavListItemOnClick(element);
    }

    constructor(
        private ConfigManagerService: ConfigManagerService,
        protected Router: Router,
        private pageBackdropService: PageBackdropService,
        private headerService: HeaderService,
        private pageDiscountDisplayService: PageDiscountDisplayService,
        private sanitizer: DomSanitizer
    ) {
        this.app_config = this.ConfigManagerService.getConfig('app');
        this.HeaderNavLibrary = new HeaderNavLibrary();
        this.tap = ('ontouchstart' in document.documentElement);

        this.headerService.backdropFlag$.subscribe((flag) => {
            this.showNavFlag = flag;
        });

        this.pageDiscountDisplayService.websiteProductsDiscountData$.subscribe((website_product_discount_data) => {
            this.all_products_discount_data = website_product_discount_data;

            if (this.all_products_discount_data) {
                this.highestDiscount = this.getHighestDiscount(this.all_products_discount_data);
            }
        });
    }


    ngOnInit() {
        if (this.enableHeaderNavDataFetching) {
            this.getProductMenu();
        }
    }

    setHeaderNavListAsRightAlign() {
        if (window.innerWidth > 991) {
            const headerMenuNavDesktop = this.headerMenuNavDesktop.nativeElement;
            const headerMenuNavDesktopRect = headerMenuNavDesktop.getBoundingClientRect();
            const headerMenuNavXEndCoordinate = headerMenuNavDesktopRect.left + headerMenuNavDesktopRect.width;

            this.headerNavListContainers
                .toArray()
                .forEach((headerNavListContainer) => {
                    const headerNavListContainerRect = headerNavListContainer.nativeElement.getBoundingClientRect();
                    const headerNavListContainerXEndCoordinate = headerNavListContainerRect.left + headerNavListContainerRect.width;
                    if (headerNavListContainerXEndCoordinate > headerMenuNavXEndCoordinate) {
                        const catalogItemId = headerNavListContainer.nativeElement.dataset.catalogItemId;
                        Object.keys(this.desktopNav).forEach((key) => {
                            if (this.desktopNav[key].catalog_item_id == catalogItemId) {
                                this.desktopNav[key].right_align = true;
                            }
                        });
                    }
                });
        }
    }

    setHeightOfMultiLevelHeaderNavList() {
        if (window.innerWidth > 991) {
            this.headerNavListContainers
                .toArray()
                .forEach((headerNavListContainer) => {
                    let maxHeight = headerNavListContainer.nativeElement.offsetHeight;
                    const headerNavListContainersChild = headerNavListContainer.nativeElement.querySelectorAll('.comp-header-nav-list-container');

                    headerNavListContainersChild.forEach((headerNavListContainerChild) => {
                        if (headerNavListContainerChild.offsetHeight > maxHeight) {
                            maxHeight = headerNavListContainerChild.offsetHeight;
                        }
                    });

                    headerNavListContainer.nativeElement.style.minHeight = maxHeight + 'px';
                });
        }
    }

    public getProductMenu() {
        const headerNavUrl = this.app_config.site_url + '/header-menu-nav';
        const _this = this;
        this.HeaderNavLibrary.getHeaderMenuNavData(headerNavUrl, 'GET', null).then((response) => {
            this.desktopNav = this.initializeDesktopNav(response.wide_header_nav_data_2);
            this.parent_nav = response.parent_data;
            this.child_nav = response.wide_header_nav_data;

            if (this.isMobile) {
                /* eslint-disable no-self-assign */
                [this.parent_nav['374'], this.parent_nav['379'], this.parent_nav['382'], this.parent_nav['384']] =
                    [this.parent_nav['382'], this.parent_nav['379'], this.parent_nav['374'], this.parent_nav['384']];
                /* eslint-enable no-self-assign */
            }
            const width = (100 / Object.keys(_this.parent_nav).length);
            this.parent_nav_width = width;
        });
    }

    public initializeDesktopNav(nav) {
        Object.keys(nav).forEach((key) => {
            if (nav[key].url) {
                if (!this.checkIfValidUrl(nav[key].url)) {
                    let url = nav[key].url;
                    url = url.charAt(0) == '/' ? url : `?${url}`;
                    nav[key].url = this.app_config.site_url + url;
                }

                if (nav[key].url_params) {
                    const separator = nav[key].url.indexOf('?') > -1 ? '&' : '?';
                    nav[key].url += (separator + nav[key].url_params);
                }
            }

            if (!(nav[key].children instanceof Array && nav[key].children.length === 0)) {
                Object.keys(nav[key].children).forEach((key2) => {
                    nav[key].children[key2].children = this.initializeDesktopNav(nav[key].children[key2].children);
                });
            }
        });

        return nav;
    }

    public showChild(showNavFlag, isParent) {
        if (showNavFlag && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        if (isParent && !this.tap && window.innerWidth > 991) {
            this.showNavFlag = showNavFlag;
        }
    }

    public showChildOnTap() {
        if (this.tap) {
            if (this.showNavFlag ) {
                this.showNavFlag = false;
            } else {
                this.showNavFlag = true;
                this.setNavHeight();
            }
        }
    }

    public showBackdrop(showNavFlag) {
        this.timer = setTimeout((data) => {
            this.showNavFlag = showNavFlag;
            this.pageBackdropService.showBackDrop(showNavFlag);
        }, 300);
    }

    public removeBackDrop(showNavFlag) {
        clearTimeout(this.timer);
        this.showNavFlag = showNavFlag;
        this.pageBackdropService.showBackDrop(showNavFlag);
    }

    toggleChildOnKeyDown(event) {
        const allowedKeyCodes = [13, 27]; // [Enter, Esc]
        const allowedKeyCodesToShowElement = [13]; // [Enter]

        const isKeyCodeAllowed = allowedKeyCodes.indexOf(event.keyCode) != -1;
        const isKeyCodeAllowedToShowElement = allowedKeyCodesToShowElement.indexOf(event.keyCode) != -1;

        if (isKeyCodeAllowed) {
            if (this.showNavFlag) {
                this.showNavFlag = false;
            } else if (!this.showNavFlag && isKeyCodeAllowedToShowElement) {
                this.showNavFlag = true;
            }
        }
    }

    setNavHeight() {
        setTimeout(()=> {
            const navListHeight = innerHeight - 245;
            const mobile_nav = <HTMLElement>document.querySelector('.setNavHeight');
            if (mobile_nav) {
                mobile_nav.style.height = navListHeight + 'px';
            }
        }, 100);
    }

    public setMenuUrl(url) {
        const menu_url = url.charAt(0) != '/' ? url : this.app_config.site_url + url;
        return menu_url;
    }

    public navigateToUrl(event, url) {
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        location.href = url;
    }

    private getHighestDiscount(allProductsDiscountData) {
        let highestDiscount = 0;

        Object.keys(allProductsDiscountData).forEach(
            (discount) => {
                if (parseInt(allProductsDiscountData[discount]['discount_percentage'], 10) > highestDiscount) {
                    highestDiscount = parseInt(allProductsDiscountData[discount]['discount_percentage'], 10);
                }
            }
        );

        return highestDiscount;
    }

    public checkIfValidUrl(url) {
        try {
            url = new URL(url);
        } catch (e) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    public removeFocusedElement(event) {
        if (window.innerWidth > 991) {
            const element = event.target || event.srcElement || event.currentTarget;
            if (
                document.activeElement &&
                (
                    document.activeElement.classList.contains('main-hnl-url') ||
                    document.activeElement.classList.contains('hnl-link')
                )
            ) {
                const siblings = element.parentElement == document.activeElement.parentElement.parentElement;
                if (siblings && element !== document.activeElement.parentElement) {
                    document.activeElement.parentElement.classList.remove('active');
                } else {
                    const activeMainHnlListItem = document.activeElement.closest('.main-hnl-list-item');
                    const activeHnlListItems = activeMainHnlListItem.querySelectorAll('.hnl-list-item');

                    if (element.closest('.main-hnl-list-item') == activeMainHnlListItem) {
                        for (var i = 0; i < activeHnlListItems.length; i++) {
                            if (element.parentElement.closest('.hnl-list-item') == activeHnlListItems[i]) {
                                break;
                            }

                            activeHnlListItems[i].classList.remove('active');
                        }
                    } else {
                        const hnlListItems = document.querySelectorAll('.main-hnl-list-item.active, .hnl-list-item.active');
                        for (var i = 0; i < hnlListItems.length; i++) {
                            hnlListItems[i].classList.remove('active');
                        }
                    }
                }

                (document.activeElement as HTMLElement).blur();
            }
        }
    }

    public asIsOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
        return 0;
    }

    public setClassForHeaderNavListItem() {
        if (window.innerWidth > 991) {
            const hnlListItems = document.querySelectorAll('.main-hnl-list-item, .hnl-list-item');
            for (let i = 0; i < hnlListItems.length; i++) {
                if (hnlListItems[i].matches(':focus-within')) {
                    hnlListItems[i].classList.add('active');
                } else {
                    hnlListItems[i].classList.remove('active');
                }
            }
        } else {
            if (
                !this.headerMenuNavDesktop.nativeElement.matches(':focus-within') &&
                this._showMobileNav
            ) {
                this.toggleMenu.emit(false);
            }
        }
    }

    public setClassForHeaderNavListItemOnClick(element) {
        if (!element.classList.contains('menu-toggle') && !element.closest('.header-menu-nav-container.desktop')) {
            if ( this._showMobileNav) {
                this.toggleMenu.emit(false);
            }

            const hnlListItems = document.querySelectorAll('.main-hnl-list-item.active, .hnl-list-item.active');
            for (let i = 0; i < hnlListItems.length; i++) {
                hnlListItems[i].classList.remove('active');
            }
        }
    }

    public setItemAsActiveShown(catalogItemId) {
        if (window.innerWidth <= 991) {
            this.activeShownElements[catalogItemId] = true;
            this.isMainHnlListActiveButHidden = true;

            this.headerNavListContainers
                .toArray()
                .forEach((headerNavListContainer) => {
                    headerNavListContainer.nativeElement.style.minHeight = 'auto';
                });
        }
    }

    public goToPreviousNavList(e, catalogItemId) {
        if (window.innerWidth <= 991) {
            e.stopPropagation();
            this.activeShownElements[catalogItemId] = false;
            this.isMainHnlListActiveButHidden = false;
        }
    }

    public setChildrenAsActiveButHidden(val) {
        if (window.innerWidth <= 991) {
            this.isChildrenActiveButHidden = val;
        }
    }

    public processRedirect(e, children) {
        if (window.innerWidth <= 991 && children) {
            e.preventDefault();
        }
    }

    public get windowInnerWidth() {
        return window.innerWidth;
    }
}
