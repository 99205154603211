<header class="global-header-container" [hidden]="!enabled_sections.show_header">
    <div class="skip-main-content">
        <a tabindex="0" (keydown)="scrollIntoView('main_content', $event)">Skip to main content</a>
    </div>
    <announcement-placeholder
        class="comp-announcement-container"
        *ngIf="enabled_sections.holiday_banner"
        [endPointUrl]="app_config.submodule_api_url + '/announcements'"
        [placeholderCode]="'HEADER_TOP'"
        [websiteCode]="app_config.website_code"
        [containerClass]="'header-top'"
        [closeIconClass]="'fehp-close'"></announcement-placeholder>
    <div class="wide-header-top-container container hidden-sm hidden-xs">
        <div class="header-logo col-md-6 col-xs-12">
             <a href="{{app_config.site_url}}" attr.aria-label="48HourPrint.com logo, {{app_config.site_url}}">
                 <img class="logo" src="{{app_config.client_asset_path}}/img/48hp-logo.gif" alt="48HourPrint.com" title="48HourPrint.com">
             </a>
        </div>
        <div class="search-wrapper col-md-11 col-xs-24">
            <div #desktop_search_widget [ngClass]="{'hidden': !enabled_sections.search_bar}" id="desktop_search_widget" class="search-widget-wrapper">
                <standard-searchbar></standard-searchbar>
            </div>
        </div>
        <div class="account-cart-wrapper col-md-7 col-xs-12">
            <div class="my-account-wrapper" id="my_account_wrapper_desktop" tabindex="0" attr.aria-label="{{ is_logged_in ? 'Hello, ' + customer_info?.first_name + '. My Account' : 'Hello, Log In! My Account'}}" aria-haspopup="true" aria-expanded="false" role="menu"
                [ngClass]="{'active' : showAccountDropDownFlag}" (click)="showAccountDropDownOnClick()" (mouseenter)= "showAccountDropDown(true)"  (mouseleave) = "showAccountDropDown(false)"
                (keydown)="toggleAccountDropDownUponKeyDown($event)" (focusout)="hideAccountDropdownOnFocusOut($event)"
                [style.z-index]="showAccountDropDownFlag ? '3000' : 'auto'">
                <span class="account-container customer-loaded">
                    <span class="salutation">
                        Hello, {{ is_logged_in ? customer_info?.first_name : 'Log In'}}!
                    </span>
                    <span class="">
                        My Account
                    </span>
                </span>
                <span class="gary-wrapper">
                    <img src="assets/img/gary.svg" width="100%" height="auto">
                </span>
                <div class="customer-settings" [hidden]="!showAccountDropDownFlag">
                    <ul>
                        <li *ngIf="!is_logged_in" class="login-button site-btn-primary" tabindex="0" aria-label="Login" role="menuitem" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">
                            <span>LOGIN OR CREATE ACCOUNT</span>
                        </li>
                        <hr *ngIf="!is_logged_in" />
                        <li>
                          <a href="{{app_config.portal_client_url}}/order" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">Orders & Proofs</a>
                        </li>
                        <li>
                          <a href="{{app_config.portal_client_url}}/quotes" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">My Quotes</a>
                        </li>
                        <li>
                          <a href="{{app_config.portal_client_url}}/my-saved-designs" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">Saved Designs</a>
                        </li>
                        <li>
                          <a href="{{app_config.portal_client_url}}/mailing-list" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">Mailing List</a>
                        </li>
                        <li>
                          <a href="{{app_config.portal_client_url}}/setting/contact-information" (click)="loadLoginRegister('login', $event)" (keydown)="loadLoginRegister('login', $event)">Settings</a>
                        </li>
                        <li *ngIf="is_logged_in">
                          <a href="javascript:void(0)" (click)="logout()" (keydown)="logout($event)">Log Out</a>
                        </li>
                    </ul>
                </div>
            </div>
            <cart-count></cart-count>
            <cs-widget></cs-widget>
        </div>
    </div>

    <div class="mobile-header-container hidden-md hidden-lg clearfix">
        <div class="header-logo col-md-7 col-xs-14">
             <a href="{{app_config.site_url}}">
                  <img class="hidden-md hidden-lg" src="assets/img/48hp-logo-mobile.png" alt="48HourPrint.com" title="48HourPrint.com">
             </a>
        </div>
            <div class="account-cart-wrapper col-md-7 col-xs-10" (click)="hideNav()">
                <div class="my-account-wrapper" (click)="toggleCustomerNav($event)">
                    <span class="gary-wrapper">
                        <img src="assets/img/gary.svg" width="100%" height="auto">
                    </span>
                    <span class="my-account-label">My Account</span>
                </div>
                <cart-count></cart-count>
            </div>
            <div class="customer-settings mobile-customer-settings col-xs-24" *ngIf="showCustomerNav">
                <ul>
                    <li *ngIf="!is_logged_in" class="login-button site-btn-primary" (click)="loadLoginRegister()">
                        <span>LOGIN OR CREATE ACCOUNT</span>
                    </li>
                    <hr *ngIf="!is_logged_in" />
                    <li>
                      <a href="{{app_config.portal_client_url}}/order" (click)="loadLoginRegister('login', $event)">Orders & Proofs</a>
                    </li>
                    <li>
                      <a href="{{app_config.portal_client_url}}/quotes" (click)="loadLoginRegister('login', $event)">My Quotes</a>
                    </li>
                    <li>
                      <a href="{{app_config.portal_client_url}}/my-saved-designs" (click)="loadLoginRegister('login', $event)">Saved Designs</a>
                    </li>
                    <li>
                      <a href="{{app_config.portal_client_url}}/mailing-list" (click)="loadLoginRegister('login', $event)">Mailing List</a>
                    </li>
                    <li>
                      <a href="{{app_config.portal_client_url}}/setting/contact-information" (click)="loadLoginRegister('login', $event)">Settings</a>
                    </li>
                    <li *ngIf="is_logged_in">
                        <a href="javascript:void(0)" (click)="logout()">Log Out</a>
                    </li>
                </ul>
            </div>

            <div class="search-wrapper col-sm-24 col-xs-24">
                <span class="menu-toggle hidden-md hidden-lg" (click)="showNavigation($event)"
                    [ngClass]="{
                        'fehp-hamburger-menu': !show_mobile_nav,
                        'fehp-close': show_mobile_nav,
                        'hidden': !enabled_sections.product_nav
                    }"></span>
                <div #mobile_search_widget [ngClass]="{'hidden': !enabled_sections.search_bar}" id="mobile_search_widget" class="search-widget-wrapper">
                    <standard-searchbar></standard-searchbar>
                </div>
            </div>
    </div>

    <div class="bottom-header-wrapper">
        <app-header-nav [enableHeaderNavDataFetching]="true" *ngIf="enabled_sections.product_nav" [showMobileNav]="show_mobile_nav" (toggleMenu)="showNavigation($event)"></app-header-nav>
    </div>
    <page-backdrop></page-backdrop>
</header>

<div class="comp-header-banner-container">
    <announcement-placeholder
        class="comp-announcement-container"
        *ngIf="enabled_sections.holiday_banner"
        [endPointUrl]="app_config.submodule_api_url + '/announcements'"
        [placeholderCode]="'HEADER_BANNER'"
        [websiteCode]="app_config.website_code"
        [containerClass]="'header-banner'"
        [itemClass]="'header-banner-item'"
        [closeIconClass]="'fehp-close'"></announcement-placeholder>
    <holiday-message *ngIf="enabled_sections.holiday_banner && siteConfig.enable_holiday_message"></holiday-message>
    <coupon-banner-widget *ngIf="enabled_sections.holiday_banner && (discountConfig?.coupon_banner && discountConfig.coupon_banner?.enabled)"></coupon-banner-widget>
</div>
