import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DriCommonModule} from '@dri/common/common.module';
import {PageModule} from '@dri/page/page.module';
import {NotFoundComponent} from './components/not-found.component';

@NgModule({
    imports: [
        CommonModule,
        DriCommonModule,
        PageModule,
    ],
    exports: [
        NotFoundComponent,
    ],
    declarations: [
        NotFoundComponent,
    ],
    providers: [],
})
export class NotFoundModule {
}
