<div class="site-alert-danger error-messages-component"
     *ngIf="error_messages?.length">
    <div class="error-wrap alert-title">
        <h4 *ngIf="header_message">{{header_message}}</h4>
        <ul class="error-display">
            <li *ngFor="let error of error_messages">
                <i class="fehp-warning wrap-icon"
                   aria-hidden="true"></i>
                <span for="email"
                      class="error">
                    {{error}}
                </span>
            </li>
        </ul>
    </div>
</div>
