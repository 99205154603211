import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {KeyValue} from '@angular/common';

@Component({
    selector: 'header-nav-list',
    templateUrl: 'header-nav-list.component.html',
})
export class HeaderNavListComponent implements OnInit {
    @Input() isMobile: boolean;
    @Input() allProductsDiscountData: any;
    @Input() navData: any;
    @Input() level: number;
    @Input() parentNav: string;
    @Input() parentNavTitle: string;
    @Output() setGroupAsActiveButHiddenEvent = new EventEmitter<boolean>();

    protected appConfig;
    protected additionalClasses = 'single-column';
    public activeShownElements = {};
    public isMainHnlListActiveButHidden = false;
    public isChildrenActiveButHidden = false;

    constructor(
        protected ConfigManagerService: ConfigManagerService
    ) {
        this.appConfig = ConfigManagerService.getConfig('app');
    }

    ngOnInit(): void {
        if (this.parentNav && Object.keys(this.parentNav).length > 1) {
            this.additionalClasses = 'multi-column';
        }
    }

    checkIfChildrenIsEmpty(navItemChildren: any) {
        return navItemChildren instanceof Array &&
               navItemChildren.length == 0;
    }

    checkIfValidUrl(url) {
        try {
            url = new URL(url);
        } catch (e) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    constructUrl(url: string) {
        if (! url) {
            return 'javascript:';
        }

        if (this.checkIfValidUrl(url)) {
            return url;
        }

        url = url.charAt(0) == '/' ? url : `?${url}`;
        return this.appConfig.site_url + url;
    }

    public removeFocusedElement(event) {
        if (window.innerWidth > 991) {
            const element = event.target || event.srcElement || event.currentTarget;
            if (
                document.activeElement &&
                (
                    document.activeElement.classList.contains('main-hnl-url') ||
                    document.activeElement.classList.contains('hnl-link')
                )
            ) {
                const siblings = element.parentElement == document.activeElement.parentElement.parentElement;
                if (siblings && element !== document.activeElement.parentElement) {
                    document.activeElement.parentElement.classList.remove('active');
                } else {
                    const activeMainHnlListItem = document.activeElement.closest('.main-hnl-list-item');
                    const activeHnlListItems = activeMainHnlListItem.querySelectorAll('.hnl-list-item');

                    if (element.closest('.main-hnl-list-item') == activeMainHnlListItem) {
                        for (var i = 0; i < activeHnlListItems.length; i++) {
                            if (element.parentElement.closest('.hnl-list-item') == activeHnlListItems[i]) {
                                break;
                            }

                            activeHnlListItems[i].classList.remove('active');
                        }
                    } else {
                        const hnlListItems = document.querySelectorAll('.main-hnl-list-item.active, .hnl-list-item.active');
                        for (var i = 0; i < hnlListItems.length; i++) {
                            hnlListItems[i].classList.remove('active');
                        }
                    }
                }

                (document.activeElement as HTMLElement).blur();
            }
        }
    }

    asIsOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
        return 0;
    }

    public setItemAsActiveShown(catalogItemId, children) {
        if (window.innerWidth <= 991 && !this.checkIfChildrenIsEmpty(children)) {
            this.activeShownElements[catalogItemId] = true;
            this.isMainHnlListActiveButHidden = true;
            this.setGroupAsActiveButHidden(true);
        }
    }

    public goToPreviousNavList(e, catalogItemId) {
        if (window.innerWidth <= 991) {
            e.stopPropagation();
            this.activeShownElements[catalogItemId] = false;
            this.isMainHnlListActiveButHidden = false;
            this.setGroupAsActiveButHidden(false);
        }
    }

    public setGroupAsActiveButHidden(value) {
        if (window.innerWidth <= 991) {
            this.setGroupAsActiveButHiddenEvent.emit(value);
        }
    }

    public setChildrenAsActiveButHidden(val) {
        if (window.innerWidth <= 991) {
            this.isChildrenActiveButHidden = val;
        }
    }

    public processRedirect(e, children) {
        if (window.innerWidth <= 991 && !this.checkIfChildrenIsEmpty(children)) {
            e.preventDefault();
        }
    }

    public get windowInnerWidth() {
        return window.innerWidth;
    }
}
