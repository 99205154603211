import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {EnhancedEcommerceLib} from '@dri/enhanced-ecommerce-lib';
import {VisitorIdLib} from '@dri/visitor-id-lib';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DriCommonModule} from '@dri/common/common.module';
import {AppHeaderComponent} from './components/app-header.component';
import {AppFooterComponent} from './components/app-footer.component';
import {AppHeaderNavComponent} from './components/app-header-nav.component';
import {CartCountComponent} from './components/cart-count.component';
import {CsWidgetComponent} from './components/cs-widget.component';
import {CartResolver} from './resolver/cart-resolver';
import {FFrCartResolver} from './resolver/ffr-cart-resolver';
import {UrlCouponResolver} from './resolver/url-coupon-resolver';
import {FfrCartCountService} from './service/ffr-cart-count-service';
import {PageModule} from '@dri/page/page.module';
import {CartCountService} from './service/cart-count-service';
import {AppHeaderBannerComponent} from './components/app-header-banner.component';
import {CouponModule} from '@dri/coupon/coupon.module';
import {RecentlyViewedProductsService} from './service/recently-viewed-products-service';
import {PageBackdropComponent} from './components/page-backdrop.component';
import {CustomerModule} from '@dri/customer/customer.module';
import {LoginRegisterComponent} from './components/login/login-register.component';
import {LoginRegisterHostDirective} from './components/login/login-register-host.directive';
import {LoginRegisterHostComponent} from './components/login/login-register-host.component';
import {LoginHandlerComponent} from './components/login/login-handler.component';
import {RegisterHandlerComponent} from './components/login/register-handler.component';
import {ForgotPasswordHandlerComponent} from './components/login/forgot-password-handler.component';
import {LoginRegisterBuilder} from '@dri/dri-login-register';
import {LoginRegisterService} from './service/login-register.service';
import {PageBackdropService} from './service/page-backdrop.service';
import {LoginRegisterModalComponent} from './components/login/login-register-modal.component';
import {SiteObjNgForPipe} from './pipes/site-obj-ng-for.pipe';
import {HolidayModule} from '@dri/holiday/holiday.module';
import {HeaderService} from './service/header.service';
import {NewsletterComponent} from './components/newsletter.component';
import {PageDiscountDisplayService} from './service/page-discount-display-service';
import {NewsletterModalComponent} from './components/newsletter-modal.component';
import {NewsletterModalTriggerDirective} from './directives/newsletter-modal-trigger.directive';
import {AdmanagerResolver} from './resolver/admanager-resolver';
import {AnnouncementModule} from '@dri/announcement/announcement.module';
import {StandardSearchBarModule} from '@dri/dri-search-bar/component/standard-searchbar/standard-searchbar.component';
import {AppCommonModule} from '../common/common.module';
import {HeaderNavListComponent} from './components/header-nav-list.component';
import {GA4NavClick} from '@dri/marketing/src/ga4-nav-click';
import {RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ModalModule,
        DriCommonModule,
        PageModule,
        CouponModule,
        CustomerModule,
        HolidayModule,
        ReactiveFormsModule,
        AnnouncementModule,
        AppCommonModule,
        StandardSearchBarModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    exports: [
        AppHeaderComponent,
        AppHeaderBannerComponent,
        AppFooterComponent,
        AppHeaderNavComponent,
        CsWidgetComponent,
        PageBackdropComponent,
        NewsletterComponent,
        NewsletterModalComponent,
        NewsletterModalTriggerDirective,

        LoginRegisterComponent,
        LoginRegisterModalComponent,
        LoginRegisterHostComponent,
        SiteObjNgForPipe,
    ],
    declarations: [
        AppHeaderComponent,
        AppHeaderBannerComponent,
        AppFooterComponent,
        AppHeaderNavComponent,
        CartCountComponent,
        CsWidgetComponent,
        HeaderNavListComponent,
        PageBackdropComponent,
        NewsletterComponent,
        NewsletterModalComponent,
        NewsletterModalTriggerDirective,

        LoginRegisterComponent,
        LoginRegisterModalComponent,
        LoginRegisterHostComponent,
        LoginRegisterHostDirective,
        LoginHandlerComponent,
        RegisterHandlerComponent,
        ForgotPasswordHandlerComponent,
        SiteObjNgForPipe,
    ],
    providers: [
        CartCountService,
        FfrCartCountService,
        CartResolver,
        FFrCartResolver,
        UrlCouponResolver,
        AdmanagerResolver,
        RecentlyViewedProductsService,
        PageBackdropService,
        HeaderService,
        PageDiscountDisplayService,
        {provide: 'TrackerApiService', useValue: new VisitorIdLib},
        {provide: 'EnhancedEcommerceLib', useValue: new EnhancedEcommerceLib},
        LoginRegisterService,
        {provide: 'LoginBuilder', useValue: new LoginRegisterBuilder},
        {provide: 'RegisterBuilder', useValue: new LoginRegisterBuilder},
        {provide: 'ForgotPasswordBuilder', useValue: new LoginRegisterBuilder},
        {provide: 'GA4NavClick', useValue: new GA4NavClick},
    ],
})
export class CoreModule {
}
