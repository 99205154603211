import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@dri/auth/services/auth.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {StorageService} from '@dri/common/services/storage.service';
import {throwError} from 'rxjs';
import {CouponStorageService} from '@dri/coupon/services/coupon-storage.service';

@Injectable()
export class CartResolver implements Resolve<any> {
    private vid;
    private storageKeys:any;

    constructor(
        private Http: HttpClient,
        private ConfigManagerService: ConfigManagerService,
        private StorageService: StorageService,
        private Router: Router,
        private AuthService: AuthService,
        private CouponStorageService : CouponStorageService
    ) {
        this.storageKeys = this.ConfigManagerService.getConfig('storage_keys');
        this.vid = this.StorageService.getItem(this.storageKeys.visitor_id);
    }

    /**
     * Use for creating cart items
     */
    getCartData(couponFromStorageData) {
        const cart_id = this.StorageService.getItem(this.storageKeys.cart_id);
        const appConfig = this.ConfigManagerService.getConfig('app');
        const couponConfig = this.ConfigManagerService.getConfig('coupon');
        let endPoint = appConfig.checkout_api_url + '/secured-compute-cart/' + cart_id + '?vid=' + this.vid;

        if (couponFromStorageData &&
            (
                typeof couponConfig.coupon_section == 'undefined' ||
                typeof couponConfig.coupon_section.use_attached_discount_from_db_only == 'undefined' ||
                !couponConfig.coupon_section.use_attached_discount_from_db_only
            )
        ) {
            if (typeof couponFromStorageData['pc'] !== 'undefined') {
                endPoint = endPoint + '&product_coupon_code=' + couponFromStorageData['pc'][0];
            }
            if (typeof couponFromStorageData['sc'] !== 'undefined') {
                endPoint = endPoint + '&shipping_coupon_code=' + couponFromStorageData['sc'][0];
            }
        }
        return this.Http.get(endPoint);
    }

    parseJsonString(str: string) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const cart_id = this.StorageService.getItem(this.storageKeys.cart_id);
        const siteUrl = this.ConfigManagerService.getConfig('app').site_url;
        if (!cart_id) {
            window.location.href = siteUrl;
            return throwError('NO CART');
        }
        const _this = this;
        return new Promise((resolve, reject) => {
            // get coupon data from local storage using cross storage hub
            const couponFromStorageData = _this.CouponStorageService.getCoupon();
            return _this.getCartData(couponFromStorageData).subscribe((response) => {
                const cartData = response['document'];
                if (cartData.items && Object.keys(cartData.items).length) {
                    if (route['data']['require_shipping'] && !cartData.all_mailing_items) {
                        const items = cartData.items;
                        for (const item_id in items) {
                            if (items[item_id]['shipping_info'] === null) {
                                _this.Router.navigate([
                                    _this.ConfigManagerService.getConfig('routes').checkout_client.shipping,
                                ]);
                            }
                        }
                    }

                    // skip shipping page if all items are for mailing
                    if (state['url'] == '/shipping' && cartData.all_mailing_items) {
                        _this.Router.navigate([
                            _this.ConfigManagerService.getConfig('routes').checkout_client.payment,
                        ]);
                    }

                    resolve(cartData);
                    return cartData;
                }

                window.location.href = siteUrl;
                reject('NO ITEMS');
            }, (error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    _this.AuthService.clearAccessToken();
                    _this.Router.navigate([
                        _this.ConfigManagerService.getConfig('routes').checkout_client.login,
                    ]);
                } else if (error.status === 404 || error.status === 403) {
                    window.location.href = siteUrl;
                }
                reject(error);
            });
        });
    }
}
