import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {CartCountService} from '../service/cart-count-service';
import {StorageService} from '@dri/common/services/storage.service';
import {RefreshTokenService} from '@dri/auth/services/refresh-token.service';
import {Location} from '@angular/common';

@Component({
    selector: 'cart-count',
    templateUrl: 'cart-count.component.html',
})

export class CartCountComponent implements OnInit {
    public app_config: any;
    public po_cart_count = 0;
    public ffr_cart_count = 0;
    public show_cart_count_dropdown = false;
    public poCartRoute:string;
    public ffrCartRoute:string;
    public poCartId;
    public isDesignToolPage = false;

    constructor(
        protected CartCountService : CartCountService,
        protected Router : Router,
        protected ConfigManagerService: ConfigManagerService,
        protected StorageService: StorageService,
        protected RefreshTokenService: RefreshTokenService,
        private Location: Location
    ) {
        this.Router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isDesignToolPage = false;
                if (this.Location.path().search('/design/tool') >= 0) {
                    this.isDesignToolPage = true;
                }
            }
        });

        this.app_config = this.ConfigManagerService.getConfig('app');
        this.poCartRoute = this.ConfigManagerService.getConfig('routes').checkout_client.cart;
        this.ffrCartRoute = this.ConfigManagerService.getConfig('routes').checkout_client.ffr_cart;

        this.poCartId = this.StorageService.getItem(
            this.ConfigManagerService.getConfig('storage_keys').cart_id
        );
    }

    ngOnInit() {
        this.CartCountService.getCartCount(this.poCartId).then((cartCount) => {
            this.po_cart_count = Number(cartCount);
        });

        this.CartCountService.cart_count$.subscribe(
            (cart_count) => {
                this.po_cart_count = cart_count;
            }
        );

        this.RefreshTokenService.persistentCart$.subscribe((res) => {
            if (res) {
                if (res.hasOwnProperty('cart_count')) {
                    this.po_cart_count = Number(res['cart_count']);
                }
            }
        });

        window.document.addEventListener('click', () => {
            if (this.show_cart_count_dropdown) {
                this.show_cart_count_dropdown = !this.show_cart_count_dropdown;
            }
        });
    }
}
