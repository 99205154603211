import {Injectable} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';

declare let searchClientVersion: string;

@Injectable({
    providedIn: 'root',
})

export class SearchClientLoaderService {
    private isLoaded: any = {};
    private searchConfigs: any;

    constructor(
        private ConfigManagerService: ConfigManagerService
    ) {
        this.searchConfigs = this.ConfigManagerService.getConfig('search_client');
    }

    public loadSearchFeature(code) {
        if (!this.searchConfigs.loaders[code]) {
            throw new TypeError('No loader set up for this config.');
        }

        if (this.isLoaded[code] && this.isLoaded[code].loaded) {
            return new Promise((resolve) => {
                resolve(true);
            });
        }

        if (this.isLoaded[code] && this.isLoaded[code].loading) {
            return this.isLoaded[code].callback;
        }

        const callback = new Promise((resolve, reject) => {
            const script = document.createElement('script') as any;
            script.type = 'text/javascript';
            script.src = this.searchConfigs.url + this.searchConfigs.loaders[code] + searchClientVersion;

            if (script.readyState) {
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        this.isLoaded[code].loading = false;
                        this.isLoaded[code] = true;
                        resolve(true);
                    }
                };
            } else {
                script.onload = () => {
                    this.isLoaded[code].loading = false;
                    this.isLoaded[code] = false;
                    resolve(true);
                };
            }

            document.getElementsByTagName('head')[0].appendChild(script);
        });

        this.isLoaded[code] = {
            loading: true,
            loaded: false,
            callback: callback,
        };

        return callback;
    }
}
