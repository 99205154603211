<div class="comp-forgot-password-handler-container">
	<div class="form-password-form" *ngIf="!success_reset">
        <div class="heading-container">
            <h2 class="header-title">Forgot Password?</h2>
        </div>
		<div class="error-messages-container">
			<app-error-messages [error_messages]='forgotPasswordErrorMessages'></app-error-messages>
		</div>
		<h5> Enter your email and a link to reset your password will be emailed to you shortly. </h5>
		<form (ngSubmit)="resetPassword()"
		      novalidate
		      autocomplete="off"
		      forgotPasswordForm
		      [form_data]="form_data">

			<div class="form-group" *ngIf="showRegisterForm">
				Don't have an account?
				<a class="form-tab-pane-trigger login-reg-link reg-link" (click)="switchForm('register')" data-no-redirect="1" data-qaid="noReturning">Create a new account</a>
			</div>
			<div class="form-group">
				<div class="btn-container">
					<button type="submit"
					        class="site-btn-primary forgot-password-submit-btn btn-lg"
					        data-qaid="forgotPasswordSubmitButton"
					        focus-out
					        [disabled]="processing_request">
					    <span *ngIf="!processing_request; else processing">RESET MY PASSWORD</span>
					    <ng-template #processing>
					        <span>
					            <i class="fa fa-spinner fa-spin"></i> Processing...
					        </span>
					    </ng-template>
					</button>
				</div>
			</div>
			<div class="form-group link-section">
                <p>
                    <a (click)="switchForm('login')" data-qaid="loginLink">
                        Back to Log In
                    </a>
                </p>

			</div>
		</form>
	</div>
	<div class="form-password-success-message" *ngIf="success_reset">
        <div class="site-alert-success" role="alert">
            <div class="error-wrap alert-title">
                <i class="fehp-checkmark pull-left"></i>
                <div class="success-reset-message">
                    Password reset link has been emailed to: {{ForgotPasswordBuilder.form_inputs.email}}. If the email does not arrive within 5 minutes, please check your Spam folder. If nothing else works, call customer service at 800-844-0599.
                </div>
            </div>
        </div>
        <div class="form-group link-section">
            <p>
                <a (click)="switchForm('login')"  data-qaid="loginLink">
                    Back to Log In
                </a>
            </p>
        </div>
	</div>
</div>
