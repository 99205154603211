import {Injectable} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';

@Injectable()
export class CouponStorageService {
    constructor(
        private ConfigManagerService: ConfigManagerService
    ) {}

    public getCouponCodeFromStorageData(couponStorageObject) {
        if (couponStorageObject) {
            if (typeof couponStorageObject['pc'] !== 'undefined' && typeof couponStorageObject['pc'][0] !== 'undefined') {
                return couponStorageObject['pc'][0];
            } else if (typeof couponStorageObject['sc'] !== 'undefined' && typeof couponStorageObject['sc'][0] !== 'undefined') {
                return couponStorageObject['sc'][0];
            }
        } else {
            return null;
        }
    }
}
