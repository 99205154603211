import {Component, OnInit, ElementRef, Renderer2, ViewChild, HostListener, AfterViewInit, Inject} from '@angular/core';
import {AuthService} from '@dri/auth/services/auth.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {LogoutService} from '@dri/customer/services/logout.service';
import {Router, NavigationEnd} from '@angular/router';
import {Location} from '@angular/common';
import {LoginRegisterService} from './../service/login-register.service';
import {PageBackdropService} from './../service/page-backdrop.service';
import {HeaderService} from '../service/header.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {SearchClientLoaderService} from '../service/search-client-loader.service';
import {GA4NavClick} from '@dri/marketing/src/ga4-nav-click';

declare var SearchClient;

@Component({
    selector: 'app-header',
    templateUrl: 'app-header.component.html',
})
export class AppHeaderComponent implements OnInit, AfterViewInit {
    public app_config: any;
    public siteConfig: any;
    public discountConfig: any;
    public customer_info: any;
    public is_logged_in = false;
    public lastScrollTop = 0;
    public loginRoute: string;

    displaySearchBar = true;
    public show_component = true;
    public no_header_paths: Array<string>;
    @ViewChild('global_header_container') global_header_container: ElementRef;
    @ViewChild('desktop_search_widget', {static: true}) desktopSearchWidget;
    @ViewChild('mobile_search_widget', {static: true}) mobileSearchWidget;

    private timer: any;
    public isWindowScrolled = false;
    public showAccountDropDownFlag = false;
    public show_mobile_nav = false;
    public is_burger_click = false;
    public showCustomerNav = false;
    private tap: any;
    public myAccountWrapper: HTMLElement;
    public crossStorageConfig: any;
    public isDesignToolPage = false;

    public enabled_sections = {
        show_header: false,
        search_bar: false,
        product_nav: false,
        holiday_banner: false,
    };

    constructor(
        protected AuthService: AuthService,
        protected LogoutService: LogoutService,
        protected Router: Router,
        private elementRef: ElementRef,
        private Renderer: Renderer2,
        private Location: Location,
        private ConfigManagerService: ConfigManagerService,
        private loginRegisterService: LoginRegisterService,
        private pageBackdropService: PageBackdropService,
        private headerService: HeaderService,
        protected CrossStorageClientService: CrossStorageClientService,
        private SearchClientLoaderService: SearchClientLoaderService,
        @Inject('GA4NavClick') protected GA4NavClick: GA4NavClick
    ) {
        this.app_config = this.ConfigManagerService.getConfig('app');
        this.siteConfig = this.ConfigManagerService.getConfig('site');
        this.discountConfig = this.ConfigManagerService.getConfig('discount');
        this.loginRoute = this.ConfigManagerService.getConfig('routes').checkout_client.login;
        this.no_header_paths = this.ConfigManagerService.getConfig('no_header_paths');
        this.tap = ('ontouchstart' in document.documentElement);
        this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
        this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);

        this.headerService.backdropFlag$.subscribe((flag) => {
            this.showAccountDropDownFlag = flag;
            this.showCustomerNav = flag;
        });

        if (this.AuthService.parseAccessTokenData()) {
            const accessTokenData = this.AuthService.parseAccessTokenData();
            this.customer_info = accessTokenData.data;
            this.is_logged_in = true;
        }

        AuthService.logged_in$.subscribe(
            (accessToken) => {
                const accessTokenData = this.AuthService.parseAccessTokenData();
                this.customer_info = accessTokenData.data;
                this.is_logged_in = true;
            }
        );
        AuthService.logged_out$.subscribe(
            (data) => {
                this.customer_info = {};
                this.is_logged_in = false;
                const storageKeysToClear = [
                	this.app_config.buy_again.storage_key,
	                this.app_config.buy_again.timeout_storage_key,
                ];
	            this.clearLocalStorage(storageKeysToClear);
	            this.clearNotification();
            }
        );

        AuthService.customerInfo$.subscribe(
            (data) => {
                if ( data == null ) {
                    this.customer_info = {};
                    this.is_logged_in = false;
                }
            }
        );

        this.AuthService.refreshToken$.subscribe(
            (accessToken) => {
                const accessTokenData = this.AuthService.parseAccessTokenData();
                if (accessTokenData) {
                    this.customer_info = accessTokenData.data;
                    this.is_logged_in = true;
                }
            }
        );

        const custom_header_routes = this.ConfigManagerService.getConfig('custom_header_routes');

        this.Router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const enabled_sections = {
                    show_header: true,
                    search_bar: true,
                    product_nav: true,
                    holiday_banner: true,
                };

                for (const route_path of Object.keys(custom_header_routes)) {
                    if (this.Location.path().search(route_path) >= 0) {
                        Object.assign(this.enabled_sections, custom_header_routes[route_path]);
                        break;
                    } else {
                        Object.assign(this.enabled_sections, enabled_sections);
                    }
                }

                this.isDesignToolPage = false;
                if (this.Location.path().search('/design/tool') >= 0) {
                    this.isDesignToolPage = true;
                }
            }
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.showCustomerNav = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.show_mobile_nav) {
            this.is_burger_click = false;
            this.show_mobile_nav = false;
        }
    }

    ngOnInit() {
        this.showHamburgerMenu();

        const self = this;
        window.addEventListener('scroll', function() {
            self.showHamburgerMenu();
        } );
    }

    ngAfterViewInit() {
        const appConfig = this.ConfigManagerService.getConfig('app');

        const self = this;
        const initializeNavClick = setInterval(function() {
            const headerNav = document.getElementsByClassName('ga4-nav-click header');

            if (headerNav.length > 0) {
                const ga4Config = {
                    mainNavText: '',
                    class: '.header',
                    ignore_hover_class: 'list-header',
                };
                self.GA4NavClick.initialize(ga4Config);
                clearInterval(initializeNavClick);
            }
        }, 500);

        this.myAccountWrapper = document.getElementById('my_account_wrapper_desktop');
        const searchLookahead = new SearchClient.SearchLookahead(appConfig.website_code);
        searchLookahead.init(this.desktopSearchWidget.nativeElement, true, {
            type: 'breakpoint',
            minWidth: 992,
        });

        const searchLookaheadMobile = new SearchClient.SearchLookahead(appConfig.website_code);
        searchLookaheadMobile.init(this.mobileSearchWidget.nativeElement, true, {
            type: 'breakpoint',
            maxWidth: 991,
        });
    }

    public logout(event?) {
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        this.showAccountDropDown(false);
        this.LogoutService.logout().subscribe((res) => {
            this.logoutRedirect();
        });
    }

    public logoutRedirect() {
        if (this.isDesignToolPage) {
            var dtFrame =
                document.getElementsByClassName('page-tool-container')[0] as HTMLIFrameElement;
            if (typeof dtFrame != 'undefined') {
                const message = JSON.stringify({
                    callFunction: 'setAllowLeave',
                    isAllowLeave: true,
                });
                dtFrame.contentWindow.postMessage(
                    message,
                    this.app_config.design_tool_domain
                );
                setTimeout(() => {
                    window.location.href = this.app_config.site_url;
                });
            }
        } else {
            window.location.href = this.app_config.site_url;
        }
    }

    public showHamburgerMenu() {
        if (typeof this.global_header_container != 'undefined') {
            const header = this.global_header_container.nativeElement;
            if (window.pageYOffset > header.offsetHeight) {
                header.classList.add('window-scrolled');
                if (window.innerWidth < 768) {
                    this.isWindowScrolled = false;
                    if (document.getElementsByClassName('window-scrolled').length !== 0) {
                        if (this.lastScrollTop > window.scrollY) {
                            this.setStyle('window-scrolled', 'top', '0');
                        } else {
                            this.setStyle('window-scrolled', 'top', '-65px');
                        }
                        this.lastScrollTop = window.scrollY;
                    }
                } else {
                    this.isWindowScrolled = true;
                    this.setStyle('window-scrolled', 'top', '0');
                }
            } else {
                if (document.getElementsByClassName('window-scrolled').length !== 0 && window.innerWidth >= 768) {
                    this.setStyle('window-scrolled', 'top', '-50px');
                }
                header.classList.remove('window-scrolled');
                this.isWindowScrolled = false;
            }
        }
    }

    public setStyle(class_name, style_key, style_value) {
        this.Renderer.setStyle((document.getElementsByClassName(class_name))[0], style_key, style_value);
    }

    public loadLoginRegister(form_type, event) {
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        this.showCustomerNav = false;
        if (!this.is_logged_in && event) {
            event.preventDefault();
        }

        if (!this.is_logged_in) {
            this.showBackdrop(false);
            this.loginRegisterService.openModal({
                form_type: form_type,
                redirect_url: event ? event.target.href : null,
            });
        }
    }

    public showAccountDropDownOnClick() {
        if (this.tap) {
            if (this.showAccountDropDownFlag) {
                this.showAccountDropDownFlag = false;
                this.pageBackdropService.showBackDrop(false);
            } else {
                this.showAccountDropDownFlag = true;
                this.pageBackdropService.showBackDrop(true);
            }
        }
    }

    public toggleAccountDropDownUponKeyDown(event) {
        const allowedKeyCodes = [9, 13, 27]; // [Tab, Enter, Esc]
        const allowedKeyCodesToShowElement = [13]; // [Enter]
        const allowedKeyCodesToHideElement = [13, 27]; // [Enter, Esc]

        const isKeyCodeAllowed = allowedKeyCodes.indexOf(event.keyCode) != -1;
        const isKeyCodeAllowedToShowElement = allowedKeyCodesToShowElement.indexOf(event.keyCode) != -1;
        const isKeyCodeAllowedToHideElement = allowedKeyCodesToHideElement.indexOf(event.keyCode) != -1;

        if (isKeyCodeAllowed) {
            if (this.showAccountDropDownFlag && isKeyCodeAllowedToHideElement) {
                this.showAccountDropDownFlag = false;
                this.pageBackdropService.showBackDrop(false);
            } else if (!this.showAccountDropDownFlag && isKeyCodeAllowedToShowElement) {
                this.showAccountDropDownFlag = true;
                this.pageBackdropService.showBackDrop(true);
            }
        }
    }

    hideAccountDropdownOnFocusOut(event) {
        const isChild = this.myAccountWrapper.contains(event.relatedTarget);

        if (!isChild) {
            this.showAccountDropDownFlag = false;
            this.pageBackdropService.showBackDrop(false);
        }
    }

    public hideNav() {
        this.showCustomerNav = false;
    }

    public showAccountDropDown(flag: boolean) {
        if (!this.tap) {
            if (flag) {
                this.showBackdrop(flag);
            } else {
                this.removeBackDrop(flag);
            }
        }
    }


    public showBackdrop(flag) {
        this.timer = setTimeout((data) => {
            this.showAccountDropDownFlag = flag;
            this.pageBackdropService.showBackDrop(flag);
        }, 300);
    }

    public removeBackDrop(flag) {
        clearTimeout(this.timer);
        this.showAccountDropDownFlag = flag;
        this.pageBackdropService.showBackDrop(flag);
    }

    public showNavigation(event) {
        this.showCustomerNav = false;
        this.is_burger_click = true;
        this.show_mobile_nav = this.show_mobile_nav ? false : true;
    }

    public toggleCustomerNav(event) {
        event.stopPropagation();
        this.show_mobile_nav = false;
        this.showCustomerNav = !this.showCustomerNav;
    }
    public scrollIntoView(elementID, event) {
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        const el = document.getElementById(elementID);
        el.scrollIntoView();
        el.focus();
    }

    public clearNotification() {
        this.CrossStorageClientService.deleteData(this.app_config.customer_notification.notification_storage);
        this.CrossStorageClientService.deleteData(this.app_config.customer_notification.notification_query_storage);
        this.CrossStorageClientService.deleteData(this.app_config.customer_notification.notification_query_data);
    }

    private clearLocalStorage(storage_keys) {
    	if (storage_keys.length <= 0) return false;

	    for (const i in storage_keys) {
		    this.CrossStorageClientService.deleteData(storage_keys[i]);
	    }
    }
}
