/**
 * Created by prince.g on 6/21/2017.
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'siteObjNgFor',
})

export class SiteObjNgForPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        return Object.keys(value).map((key) => {
            // store original key as a property
            if (typeof value[key] != 'string') {
                value[key].key = key;
                return value[key];
            }
        });
    }
}
