import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpParams,
    HttpEvent
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {CartPriceModel} from '../models/cart-price.model';

@Injectable({
    providedIn: 'root',
})
export class RecomputeInterceptor implements HttpInterceptor {
    constructor(
        private ConfigManagerService: ConfigManagerService,
        private CartPriceModel: CartPriceModel
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const recomputeResources = this.ConfigManagerService.getConfig('recompute_resources');

        // check for request url to be if excluded
        let exclude = true;
        for (const i in recomputeResources) {
            if (request.url.indexOf(recomputeResources[i]) > 1) {
                exclude = false;
            }
        }

        // do nothing if url is excluded
        if (exclude) {
            return next.handle(request);
        }

        if (request.method === 'GET') {
            let newParams = new HttpParams({fromString: request.params.toString()});
            newParams = newParams.append('compare', '1');
            newParams = newParams.append('sub_total', this.CartPriceModel.sub_total.toString());
            newParams = newParams.append('tax', this.CartPriceModel.tax.toString());
            newParams = newParams.append('discount', this.CartPriceModel.discount.toString());
            newParams = newParams.append('shipping_total', this.CartPriceModel.shipping_total.toString());
            newParams = newParams.append('total_amount', this.CartPriceModel.total_amount.toString());
            const req = request.clone({
                params: newParams,
            });
            return next.handle(req);
        } else {
            const additionalParams = this.CartPriceModel.getData();
            additionalParams.compare = 1;
            const req = request.clone({
                body: {...request.body, ...additionalParams},
            });
            return next.handle(req);
        }
    }
}
