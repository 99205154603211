import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class PageBackdropService {
    // Observable string sources
    public showBackDropFlag = new Subject<any>();

    public showBackDrop(flag: boolean) {
        this.showBackDropFlag.next(flag);
    }
}
