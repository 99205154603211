import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class HeaderService {
    // Observable string sources
    public backdropFlag$ = new Subject<any>();

    public onBackdropEvent(flag: boolean) {
        this.backdropFlag$.next(flag);
    }
}
