import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {APP_CONFIG} from './environments/environment';

if (APP_CONFIG.env === 'aws' || APP_CONFIG.env === 'aws-qa') {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.log(err));
