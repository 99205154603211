import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {emailValidator} from '@dri/common/directives/validators/email-validator.directive';
import {noWhiteSpaceValidator} from '@dri/common/directives/validators/no-whitespace-validator';
import {NewsletterService} from '@dri/customer/services/newsletter.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {AuthService} from '@dri/auth/services/auth.service';
import {HTTPCookieClient} from '@dri/http-cookie-client/src/http-cookie-client';
import {CsrfService} from '@dri/common/services/csrf.service';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'newsletter',
    templateUrl: 'newsletter.component.html',
})

export class NewsletterComponent implements OnInit {
    @Input() public eventSourceCode = 'FS';
    @ViewChild('successModal', {static: true}) public successModal: ModalDirective;
    public appConfig: any;
    public crossStorageConfig: any;
    public newsletter_form: UntypedFormGroup;
    public errorMessages: any;
    public enableSubmitBtn = false;
    public processingRequest = false;
    public subscriptionSuccess = false;
    public unsubscribedFlag = 'y';
    public isCustomerDataLoaded = false;
    public customerData: any = {};
    public customerDataField: string;
    public validationMessages = {
        email: {
            required: 'Invalid email.',
            whitespace: 'Invalid email.',
            email: 'Invalid email.',
        },
    };
    public showServerValidationMessage = false;
    public HTTPCookie: any;
    public csrfToken: string;
    public formName = 'newsletter_subscribe';
    public showCsrfError = false;
    // CAPTCHA
    public googleRecaptcha;
    public showCaptcha = true;
    public showCaptchaError = false;
    public captchaError = '';
    public captchaModel = '';
    public captchaResponse = '';
    public newsletterEmail = '';
    public isCaptchaEnabled = false;

    constructor(
        protected fb: UntypedFormBuilder,
        protected NewsletterService: NewsletterService,
        protected ConfigManagerService: ConfigManagerService,
        protected CrossStorageClientService: CrossStorageClientService,
        protected AuthService: AuthService,
        protected CsrfService: CsrfService
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.customerDataField = this.ConfigManagerService.getConfig('customer').customer_data_field;
        this.googleRecaptcha = this.ConfigManagerService.getConfig('google_recaptcha');

        AuthService.logged_in$.subscribe(
            (data) => {
                this.getCustomerData();
            }
        );
        AuthService.logged_out$.subscribe(
            (data) => {
                this.unsubscribedFlag = 'y';
            }
        );

        this.HTTPCookie = new HTTPCookieClient({
            cookieDomain: this.appConfig.domain,
            cookieAPI: this.appConfig.site_url + this.appConfig.http_cookie_endpoint,
        });

        if (this.googleRecaptcha && this.googleRecaptcha.enabled) {
            this.isCaptchaEnabled = true;
        }
    }

    public ngOnInit() {
        if (!this.csrfToken) {
            this.CsrfService.getCsrfToken(this.formName).subscribe((res) => {
                if (res['token']) {
                    this.csrfToken = res['token'];
                }
            });
        }

        this.getCustomerData();
        this.buildForm();
    }

    public getCustomerData() {
        this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
        this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
        this.CrossStorageClientService.getData(this.customerDataField)
            .then( (data) => {
                if (data) {
                    this.customerData = JSON.parse(data);
                    this.HTTPCookie.get('unsubscribed_flag', null).then((data: string) => {
                        this.unsubscribedFlag = data == 'n' ? 'n' : 'y';
                        this.isCustomerDataLoaded = true;
                    });
                } else {
                    this.isCustomerDataLoaded = true;
                }
            });
    }

    public setCustomerData(unsubscribedFlag) {
        if (this.customerData != null) {
            // const customerDataField = this.ConfigManagerService.getConfig('customer').customer_data_field;
            this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
            this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
            this.customerData['unsubscribed_flag'] = unsubscribedFlag;
            const newCustomerData = JSON.stringify(this.customerData);
            this.CrossStorageClientService.setData(this.customerDataField, newCustomerData);
        }

        this.HTTPCookie.set('unsubscribed_flag', unsubscribedFlag);
    }

    public buildForm(): void {
        this.newsletter_form = this.fb.group({
            email: ['', [Validators.required, emailValidator(), noWhiteSpaceValidator()]],
        });


        // subscribe to form changes
        this.newsletter_form.valueChanges.subscribe((data) => {
            this.errorMessages = this.getFormValidationErrors();
            if (this.errorMessages.length === 0) {
                this.enableSubmitBtn = true;
            }
        });
    }

    private getFormValidationErrors() {
        const errors = [];
        Object.keys(this.newsletter_form.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.newsletter_form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    if (typeof this.validationMessages[key][keyError] !== 'undefined') {
                        errors.push(this.validationMessages[key][keyError]);
                    }
                });
            }
        });

        return errors;
    }

    public showControlError(controlName) {
        return this.newsletter_form.get(controlName).invalid && this.newsletter_form.get(controlName).dirty;
    }

    public hasValue(controlName) {
        return this.newsletter_form.get(controlName).value;
    }

    public hasValidValue(controlName) {
        return this.newsletter_form.get(controlName).valid &&
            this.newsletter_form.get(controlName).value;
    }

    public subscribe() {
        const newsletterParams = {
            'email': this.newsletter_form.get('email').value,
            'newsletter_event_source_code': this.eventSourceCode,
            'website_code': this.appConfig.website_code,
            'company_code': this.appConfig.company_code,
            'csrf_token': this.csrfToken,
            'form_name': this.formName,
            'captcha': this.captchaResponse,
        };

        if (this.isCaptchaEnabled) {
            this.showCaptchaError = !(this.captchaResponse && this.captchaResponse.length > 0);
            if (this.showCaptchaError) {
                this.captchaError = 'Captcha is required.';
                return false;
            }
        } else {
            delete newsletterParams.captcha;
        }

        this.processingRequest = true;
        this.enableSubmitBtn = false;
        this.NewsletterService.subscribe(newsletterParams).subscribe(
            (data) => {
                if (data['response']['success']) {
                    this.subscriptionSuccess = true;
                    this.showServerValidationMessage = false;

                    if (this.AuthService.parseAccessTokenData()) {
                        const accessTokenData = this.AuthService.parseAccessTokenData();
                        const customerInfo = accessTokenData.data;

                        if (customerInfo.email.toLowerCase() == newsletterParams.email.toLowerCase()) {
                            this.setCustomerData('n');
                        }
                    }
                    this.newsletter_form.reset();
                    this.showCaptcha = false;
                } else if (data['response']['code'] === 'invalid-captcha') {
                    this.showCaptchaError = true;
                    this.captchaError = 'Invalid Captcha.';
                } else {
                    this.showServerValidationMessage = true;
                }

                this.processingRequest = false;
                this.enableSubmitBtn = true;
                this.captchaModel = '';
            },
            (error) => {
                if (error.status == 412) {
                    this.showCsrfError = true;
                }

                this.processingRequest = false;
                this.enableSubmitBtn = true;
            }
        );
    }

    public redirectToAllProducts() {
        window.location.href = this.appConfig.site_url + '/all-print-products.html';
    }

    public onModalHide(e) {
        this.captchaModel = '';
    }

    public showModal() {
        if (this.isCaptchaEnabled) {
            this.newsletterEmail = this.newsletter_form.get('email').value;
            this.showCaptcha = true;
            this.showCaptchaError = false;
            this.successModal.show();
        } else {
            this.subscribe();
        }
    }

    public onCaptchaResponse(response) {
        this.captchaResponse = response;
    }
}
