import {Component, OnInit} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-footer',
    templateUrl: 'app-footer.component.html',
})
export class AppFooterComponent implements OnInit {
    public app_config: any;
    public siteConfig: any;
    public show_component = true;
    public no_footer_paths: Array<string>;
    public current_year: any;
    public footer_nav: any;
    public hasNewsletter = false;
    public excludeNavs = ['legal'];
    public legalNavs: any;

    constructor(
        protected Router : Router,
        protected ActivatedRoute : ActivatedRoute,
        private Location: Location,
        private ConfigManagerService: ConfigManagerService,
        private Http: HttpClient
    ) {
        this.app_config = this.ConfigManagerService.getConfig('app');
        this.siteConfig = this.ConfigManagerService.getConfig('site');
        this.no_footer_paths = this.ConfigManagerService.getConfig('no_footer_paths');

        this.setFooterVisibility();
        this.getFooterNav();
        this.Router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setFooterVisibility();
                this.setNewsletterVisibility();
            }
        });
    }

    ngOnInit() {
        this.setCurrentYear();
    }

    public getFooterNav() {
        const footerNavUrl = this.app_config.site_url + '/footer-nav';
        this.Http.get(footerNavUrl).subscribe((response) => {
            if (response['footer_nav_data']) {
                this.footer_nav = this.sortFooterNav(response['footer_nav_data'].children);
            }
        });
    }

    public sortFooterNav(footerNav) {
        if (footerNav) {
            for (const navIndex in footerNav) {
                const navArray = [];
                const catalogName = footerNav[navIndex].catalog_name.toLowerCase();

                for (const jIndex in footerNav[navIndex].children) {
                    navArray[footerNav[navIndex].children[jIndex].sort] = footerNav[navIndex].children[jIndex];
                }
                footerNav[navIndex].sorted_children = navArray;

                if (this.excludeNavs.includes(catalogName)) {
                    this.legalNavs = footerNav[navIndex];
                    delete footerNav[navIndex];
                }
            }
        }
        return footerNav;
    }

    public setCurrentYear() {
        const date_today = new Date();
        this.current_year = date_today.getFullYear();
    }

    public setFooterVisibility() {
        this.show_component = true;
        for (const i in this.no_footer_paths) {
            if (this.Location.path().search(this.no_footer_paths[i]) >= 0) {
                this.show_component = false;
            }
        }
    }

    public setFooterUrl(url) {
        const footer_url = url.charAt(0) != '/' ? url : this.app_config.site_url + url;
        return footer_url;
    }

    public setNewsletterVisibility() {
        let childRoute = this.ActivatedRoute.firstChild;
        this.hasNewsletter = true;

        while (childRoute) {
            if (childRoute.snapshot.data['has_newsletter'] == 'n') {
                this.hasNewsletter = false;
                break;
            }

            childRoute = childRoute.firstChild;
        }
    }
}
