import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@dri/auth/services/auth.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {StorageService} from '@dri/common/services/storage.service';
import {of, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable()
export class FFrCartResolver implements Resolve<any> {
    private vid;
    private storageKeys:any;

    constructor(
        private Http: HttpClient,
        private ConfigManagerService: ConfigManagerService,
        private StorageService: StorageService,
        private Router: Router,
        private AuthService: AuthService
    ) {
        this.storageKeys = this.ConfigManagerService.getConfig('storage_keys');
        this.vid = this.StorageService.getItem(this.storageKeys.visitor_id);
    }

    /**
     * Use for creating cart items
     */
    getCartData() {
        const cart_id = this.StorageService.getItem(this.storageKeys.ffr_cart_id);
        const appConfig = this.ConfigManagerService.getConfig('app');
        const endPoint = appConfig.checkout_api_url + '/secured-compute-cart/' + cart_id + '?vid=' + this.vid;
        return this.Http.get(endPoint);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const cart_id = this.StorageService.getItem(this.storageKeys.ffr_cart_id);
        const siteUrl = this.ConfigManagerService.getConfig('app').site_url;
        if (!cart_id) {
            window.location.href = siteUrl;
            return throwError('NO CART');
        }

        return this.getCartData().pipe(
            mergeMap((response) => {
                const cartData = response['document'];
                if (cartData.items && Object.keys(cartData.items).length) {
                    return of(cartData);
                }

                window.location.href = siteUrl;
                return throwError('NO ITEMS');
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.AuthService.clearAccessToken();
                    this.Router.navigate([
                        this.ConfigManagerService.getConfig('routes').checkout_client.login,
                    ]);
                } else if (error.status === 404 || error.status === 403) {
                    window.location.href = siteUrl;
                }
                return throwError(error);
            })
        );
    }
}
