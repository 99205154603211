export const APP_CONFIG = {
    'env': 'aws',
    'app': {
        cookie_domain: '.48hourprint.com',
        website_code: '48HP',
        company_code: '48CO',
        site_url: 'https://www.48hourprint.com',
        checkout_client_url: 'https://store.48hourprint.com',
        portal_client_url: 'https://portal.48hourprint.com',
        static_path: 'https://s1.48hourprint.com',
        sms_image_path: 'https://staticecp.48hourprint.com/',
        static_image_path: 'https://s1.48hourprint.com/assets/img',
        client_asset_path: 'https://store.48hourprint.com/assets',
        site_name: '48HourPrint',
        partition_name: 'Checkout',
        api_url: 'https://checkout-api.48hourprint.com',
        print_api_url: 'https://print-api.digitalroominc.com',
        checkout_api_url: 'https://checkout-api.48hourprint.com',
        submodule_api_url: 'https://module-api.48hourprint.com',
        submodule_client_url: 'https://module.48hourprint.com',
        design_tool_domain: 'https://design.48hourprint.com',
        design_tool_preview_domain: 'https://design.48hourprint.com/ajax/raster/getDesignThumb.php',
        domain: '48hourprint.com',
        client_storage: 'cookie',
        tracker_cookie_name: '__uptracker',
        tracker_domain: 'https://www.48hourprint.com/muffins',
        tracker_domain_file: 'info-1.0.3-min.js',
        safetech_phone_no: '800-844-0599',
        urls: {
            livechat_url: '/live-chat',
            terms_and_condition: '/terms-of-use.html',
            privacy_url: '/privacy.html',
            terms_of_use_modal: '/terms-of-use-modal.html',
        },
        google_api: {
            key: 'AIzaSyCCi6iVdR7uxn9ikxkK_Ils0y7mFheWUlU',
            libraries: ['places'],
        },
        submodule_url: 'https://module-api.48hourprint.com',
        customer_notification: {
            'expires_in': 1,
            'notification_storage': 'customer_notif_timeout',
            'notification_query_storage': 'customer_notif_q_timeout',
            'notification_query_data': 'customer_notif_q_data',
            'query_expires_in': 2,
        },
	    buy_again: {
		    'storage_key': 'buy_again',
		    'timeout_storage_key': 'buy_again_timeout',
	    },
        algolia_search: {
            application_id: '7XXLGNQRTZ',
            search_api_key: '210a6dd2bf9a8ecfc356ffc53150ddd5',
            timeouts: {
                debounce_ms: 100,
                connect: 5,
                read: 5,
                write: 30,
            },
            products_config: {
                index_name: 'prod_48hp_products',
                click_event_name: 'product click event',
                checkout_conversion_event_name: 'product checkout conversion',
                enable_analytics: true,
                has_redirection: true,
                skip_results_page_if_single_hit: true,
                count: 11,
            },
            categories_config: {
                index_name: 'prod_48hp_categories',
                click_event_name: 'category click event',
                checkout_conversion_event_name: 'category checkout conversion',
                enable_analytics: true,
                has_redirection: false,
                count: 3,
            },
            product_templates_config: {
                index_name: 'prod_48hp_templates',
                click_event_name: 'product template click event',
                checkout_conversion_event_name: 'product template checkout conversion',
                enable_analytics: false,
                has_redirection: false,
                count: 11,
            },
            pages_config: {
                index_name: 'prod_48hp_articles',
                click_event_name: 'page click event',
                checkout_conversion_event_name: 'page checkout conversion',
                enable_analytics: false,
                has_redirection: false,
                count: 3,
            },
            minimum_text_length: 3,
            no_results_page_url: '/no-results',
            results_page_url: '/search',
            enable_analytics: true,
            analytics_script_source: '/assets/libraries/algolia-search/algolia-analytics.min.js',
        },
        http_cookie_endpoint: '/blueberry/muffins',
        cloudlab_url: 'https://editor.48hourprint.com',
    },
    'cross_storage': {
        hub_url: 'https://www.48hourprint.com/html/cross-storage/hub.html',
        hub_opt: {
            timeout: 5000,
            frameId: 'storageFrame',
        },
    },
    'features': {
        enable_ftp_upload: true,
        enable_cart_price_breakdown: true,
    },
    'abtest': {
        revenue_event_name: '48hp_revenue',
        po_revenue_event_name: '48hp_po_revenue',
        job_order_experiment_resource: '/job-order-experiment',
    },
    'customer_device': {
        device_url: '/cdevice',
    },
    'ad_manager': {
        ad_manager_log_url: '/admanager-log',
	    adm_utm_url: '/adm-utm',
	    key: 'adm_data',
        excluded_fieldnames: ['seo', 'referrer'],
    },
    'seo': {
        url_config: '__seo_w_{{website_code}}_rd_{{referring_domain}}_rs_{{referring_domain_segment}}_ul_{{url}}_seo__',
        dir_config: '__dir_w_{{website_code}}_p_0_ul_{{url}}_dir__',
    },
    'utm': {
	    key: 'utm_data',
	    utm_resource: '/utm',
    },
    'file': {
        preview: {
            domain: 'https://preview.48hourprint.com/preview',
        },
        janus: {
            upload: 'https://preview.48hourprint.com/preview',
            upload_from_url: 'https://preview.48hourprint.com/preview/uploadFromUrl',
        },
        dropbox: {
            appkey: '57lc2k9qe5hc0jg',
            multiselect: false,
            upload_url: 'https://preview.48hourprint.com/preview/uploadFromUrl',
        },
        upload: {
            url: 'https://preview.48hourprint.com/preview',
            origin: 'SITE',
            max_file_size: 600,
            max_filename_length: 185,
            standard_file_types: [
                'pdf',
                'jpg',
                'jpeg',
                'psd',
                'png',
                'tif',
                'tiff',
                'eps',
                'ai',
                'pub',
                'docx',
                'doc',
                'xls',
                'xlsx',
                'ppt',
                'pptx',
                'txt',
                'csv',
                'bmp',
                'gif',
                'svg',
                'zip',
            ],
            multi_page_file_types: [
                'pdf',
                'tiff',
                'tif',
                'ind',
                'indd',
                'zip',
                'sit',
            ],
        },
        recent_files: '/recent-files',
        enable_recent_files: true,
        enable_file_dropper_click: true,
    },
    'auth': {
        access_token_field: '48hp_au_acto',
        access_token_expires_field: '48hp_au_acxp',
        refresh_token_field: '48hp_au_reto',
        grant_type: 'password',
        client_id: '48hp-checkout-client',
        client_secret: '',
        client_storage: 'cookie',
        refresh_token_url: '/refresh-token',
        auth_enabled_domains: ['checkout-api.48hourprint.com'],
        no_auth_bearer_request: ['refresh-token', 'customer-login'],
    },
    'storage_keys': {
        cart_count: 'ccnt',
        ffr_cart_count: 'ffrccnt',
        cart_id: 'poid',
        ffr_cart_id: 'frid',
        visitor_id: 'vid',
        viewed_products_key: 'recently_viewed_products',
        customer_data: '__customer',
    },
    'customer': {
        register_url:
            'https://checkout-api.48hourprint.com/customer-registration',
        login_url: 'https://checkout-api.48hourprint.com/customer-login',
        forgot_password_url: 'https://checkout-api.48hourprint.com/password',
        customer_data_field: '__customer',
        utm_cookie_name: 'utm_data',
        customer_address_url: '/customer-address',
        validate_email_url: '/customer-email',
        save_sms_notification_resource: '/sms-notification',
        reset_password_token_validation: '/reset-password-token-validation',
        newsletter_subscription: '/newsletter-subscribe',
        clear_discount_after_logout: true,
        customer_data: '/customer-data',
    },
    'checkout': {
        get_cart_url: '/compute-cart/',
        get_calculator_data_url: '/cart-item-calculator-data',
        cart_item_resource: '/cart-item',
        get_cart_item_url: '/cart-item',
        duplicate_cart_item_url: '/cart-item',
        edit_item_url: '/cart-item',
        delete_item_url: '/cart-item',
        save_item_file_url: '/cart-item-file',
        get_persistent_cart_url: '/persist-cart',
        save_project_name_url: '/project-name',
        get_hcp_price: '/hard-copy',
        set_item_proofing: '/set-proofing',
        set_item_hcp: '/set-hard-copy',
        order_tax_resource: '/order-tax-rates',
        get_design_specs_url: '/design/specs/',
        email_invoice_resource: '/email-invoice',
        save_design_item: '/create-design-item',
        ffr_checkout_resource: '/ffr-checkout',
        ffr_po_conversion_resource: '/ffr-po-conversion',
        cart_item_mailing_resource: '/cart-item-mailing',
        update_design_item: '/update-design-item',
        cart_count_resource: '/cart-count/',
        create_cloudlab_item: '/create-cloudlab-item',
        update_cloudlab_item: '/update-cloudlab-item',
    },
    'shipping': {
        get_shipping_rates_url: '/get-shipping-rates',
        get_item_shipping_rates_url: '/get-item-shipping-rates',
        pickup_locations_url: '/pickup-locations',
        website_pickup_locations_url: '/website-pickup',
        pre_process_shipping_resource: '/pre-process-shipping', // to be cleaned up once finalized testing from optimum
        shipping_rates_cache_resource: '/shipping-rates-cache', // to be cleaned up once finalized testing from optimum
        cart_shipping_rates_resource: '/cart-shipping-rates', // to be cleaned up once finalized testing from optimum
        cart_freight_shipping_rates_resource: '/cart-freight-shipping-rates',
        shipping_tax_rate_resource: '/shipping-tax-rate',
        item_shipping_rates_resource: '/item-shipping-rates',
        item_level_shipping_rates_v2_resource: '/item-level-shipping-rates-v2',
        shipping_level_resource: '/shipping-level',
        get_shipping_method: '/shipping-method',
        get_shipping_service: '/shipping-service',
        validate_shipping_account: '/validate-shipping-account',
        save_shipping_resource: '/cart-item-shipping',
        save_item_shipping_resource: '/item-shipping',
        save_item_level_shipping_v2_resource: '/item-level-save-shipping', // new endpoint for saving shipping with the centralized logic such as packages
        persistent_zip_code_enabled: true,
        persistent_zip_code_storage_name: 'postal_code',
        get_shipping_rates: '/shipping-rates', // new endpoint for getting new shipping rates with the centralized logic such as packages
        save_shipping_rates: '/save-shipping-rates', // new endpoint for saving shipping rates with the centralized logic such as packages
        selected_pickup_storage: 'selected_pickup_storage',
    },
    'calculator': {
        compute_price_url: '/price',
        api_url: 'https://calculator.digitalroom.com',
        api_version: 'v1',
        api_key: 'calculator.site',
        api_secret: 'KEfm75#XjwSMux92zUWD9T8AafG!vwV6',
    },
    'coupon': {
        apply_coupon_url: '/apply-coupon/',
        validate_coupon_code: '/validate-coupon-code/',
        apply_product_coupon_url: '/apply-product-coupon/',
        product_valid_auto_apply_coupon_url: '/product-valid-auto-apply-coupon/',
        coupon_id_validator_url: '/coupon-by-id-validator/',
        storage_name: 'discountCode',
        show_banner_paths: [
            'upload',
            'cart',
            'shipping',
            'payment',
            'confirmation',
            'design',
            'mailing',
            'customer',
            'specs',
            'login',
        ],
        coupon_section: {
            use_attached_discount_from_db_only: true,
        },
    },
    'page': {
        get_product_menu_url:
            'https://checkout-api.48hourprint.com/spa-product-menu',
        get_page_breadcrumb_url: 'https://www.48hourprint.com/page-breadcrumb',
        get_left_nav_url: 'https://www.48hourprint.com/left-nav',
        get_dynamic_page_content_url: 'https://www.48hourprint.com/dynamic-page-content',
    },
    'search': {
        search_url: 'https://module-api.48hourprint.com/search',
        search_log_url: 'https://module-api.48hourprint.com/search-logs',
        recent_search_url: 'https://module-api.48hourprint.com/recent-search',
        product_search_count: 10,
        category_search_count: 4,
        other_search_count: 4,
        enable_search_datalayer: true,
        close_search_after_clear: true,
    },
    'search_client': {
        url: 'https://search.48hourprint.com',
        version: '?v=1',
        loaders: {
            lookahead: '/dist/searchLookahead.js',
            analytics: '/dist/searchAnalytics.js',
        },
    },
    'address_form': {
        cities_url: '/cities',
        states_url: '/states',
        zip_code_url: '/zip-code',
        address_validation_url: '/address-validation',
    },
    'payment': {
        payment_api: {
            trusted_origin: 'https://secureui.48hourprint.com',
            hpf_resource: '/hpf',
            data_collector_id_resource: '/dcid',
            process_order_resource: '/process-order',
            process_profile_order_resource: '/process-profile-order',
            payment_profile_resource: '/payment-profile',
            process_payment: '/process-payment',
            process_new_profile_order_resource: '/process-new-profile-order',
        },
        chase_payment_profile_resource: '/chase-payment-profile',
        chase_payment_resource: '/chase-payment',
        chase_payment_show_req_fields_err: true,
	    cart_cpt_payment_resource: '/cart-cpt-payment',
	    cart_terms_payment_resource: '/cart-terms-payment',
	    cart_paypal_payment_resource: '/cart-paypal-payment',
	    cart_store_credit_payment_resource: '/cart-store-credit-payment',
	    cart_pay_by_phone_resource_url: '/cart-pay-by-phone-payment',
        terms_payment_resource: '/terms-payment',
        paypal_request_resource: '/paypal-payment',
        fa_kaptcha_resource: '/fa-kaptcha',
        free_order_resource: '/free-order',
        paypal: {
            env: 'production',
        },
        safe_tech: {
            merchant_id: 300645,
        },
        scripts: {
            safe_tech: 'https://ssl.kaptcha.com/collect',
        },
    },
    'mailing': {
        item_mailing_data_resource: '/item-mailing-data',
        item_mailing_fees_resource: '/item-mailing-fees',
        mailing_list: {
            resource: '/mailing-list',
            max_file_size: 100 * 1024 * 1024, // 100MB;
            valid_file_types: [
                'csv',
                'xls',
                'xlsx',
            ],
        },
    },
    'content': {
        enabled: true,
        api_url: 'https://content-api.48hourprint.com',
        resource: '/content',
    },
    'confirmation': {
        get_job_order_resource: '/confirmation',
    },
    'survey': {
        save_survey_resource: '/survey',
    },
    'routes': {
        checkout_client: {
            login: 'customer/login',
            cart: 'cart',
            ffr_cart: 'ffr/cart',
            ffr_contact_info: 'ffr/contact-info',
            upload: 'upload',
            shipping: 'shipping',
            payment: 'payment',
            billing: 'billing',
            confirmation: 'confirmation',
            ffr_confirmation: 'ffr/confirmation',
            mailing: 'mailing',
        },
        personalization: '/personalization',
    },
    'design': {
        design_tool_url: '/design/tool',
    },
    'no_header_paths': [
        'live-chat',
    ],
    'no_footer_paths': [
        'tool',
        'live-chat',
    ],
    'incontact': {
        url: 'https://home-c17.incontact.com/inContact/ChatClient/ChatClient.aspx?bu=4595417',
        support_email: 'support@48hourprint.com',
        phone_no: '800-844-0599',
        contact_reason_options: [
            {'subject': 'I would like more information about your products or services.',
                'skill': '493a766a-6287-4269-98f1-b7a918c1de73',
            }, {
                'subject': 'I have questions about an existing order.',
                'skill': '69c25e06-c02c-4a60-b465-e77437084fdc',
            }, {
                'subject': 'I need help placing an order.',
                'skill': 'bd34c566-1abf-4bd0-887f-748a1d7ba740',
            }, {
                'subject': 'I have a general question.',
                'skill': 'a10fab41-c1b0-4b27-9caa-acb771927079',
            },
        ],
        required_fields: ['full_name', 'email'],
        weekends: [0, 6],
        weekdays: [1, 2, 3, 4, 5],
        weekends_hour: {
            start_date: '11-00',
            end_date: '20-00',
        },
        weekdays_hours: {
            start_date: '00-00',
            end_date: '23-59',
        },
        timezone: 'America/New_York',
        excluded_page: [
            '/shipping',
            '/payment',
            '/ffr/cart',
            '/ffr/shipping',
            '/ffr/payment',
            '/ffr/confirmation',
        ],
    },
    'custom_header_routes': {
        '/design/tool': {
            'search_bar': false,
            'product_nav': false,
            'show_header': true,
            'holiday_banner': false,
        },
        '/live-chat': {
            'show_header': false,
        },
    },
    'content_s3_bucket': {
        config: {
            bucketName: 'sms3-48hp-images',
            region: 'us-west-1',
            dirName: '',
        },
        path: 'https://staticecp.48hourprint.com',
    },
    'estimator_error_msg': {
        'INVALID': 'Invalid Zip Code.',
        'NO_QUOTES': 'No quotes available.',
        'REQUIRED': 'Please supply a valid zip code to estimate shipping rates',
    },
    'announcement': {
        seen_announcements_key: 'sa',
        timezone: 'America/Los_Angeles',
    },
    'recompute_resources': [
        '/pickup-locations',
        '/cart-freight-shipping-rates',
        '/cart-shipping-rates',
        '/cart-ups-shipping-rates',
        '/compute-cart',
        '/secured-compute-cart',
        '/pre-process-shipping',
        '/shipping-rates-cache',
        '/shipping-tax-rate',
        '/item-level-shipping-rates',
        '/item-shipping-rates',
    ],
    'search_bar': {
        placeholder: 'Search for Products, Pages or Templates',
        search_loader_class: 'search-bar-loader-icon fa fa-spin',
        search_bar_toggle_class: '',
        search_icon_class: '',
        inline_clear_btn_class: '',
        clear_btn: false,
        inline_clear_btn: false,
        has_toggle: false,
    },
    'shopper_approved': {
        'site_id': 36608,
        'survey_token': 'b73Gs8k1',
    },
    'customer_review': {
        'api_url': 'https://customer-review-api.48hourprint.com',
    },
    'google_recaptcha': {
        'site_key': '6LeXAs4UAAAAAFpeD8NX_wqA9mLcWCrNDtZ_ba20',
        'api': 'https://www.google.com/recaptcha/api.js?render=explicit',
        'enabled': true,
    },
    'cloudlab': {
        'getPersonalizationUrl': '/personalization/computeUrl',
        'compute_url': '/personalization/computeUrl',
        'get_cloudlab_dt_url': '/get-cloudlab-designtool',
        'add_to_cart_url': '/create-cloudlab-item',
    },
    'kinesis': {
        'domain': '48hourprint.com',
        'payload_version': '1',
        'source_app': 'checkout',
        'event_context': null,
        'event': 'cartUpdate',
    },
};
