<div class="comp-cs-widget-container wiggle" id="cs_widget_container" tabindex="0" aria-haspopup="true" role="menu" aria-label="Need Help?" aria-expanded="false"
    (click)="toggleInContact($event)" (keydown)="toggleCSWidgetOnKeyDown($event)" (focusout)="hideCSWidgetOnFocusOut($event)"
    [hidden]="!show_button" [ngClass]="{'active': isActive}">
    <div class="cs-widget-title mobile-hidden" aria-hidden="true">
        <i class="fa fa-times-circle cs-icon-times-circle"></i>Select Contact Option
    </div>
    <div class="cs-widget-body">
        <div class="cs-widget-body-left-content">
            <span class="mobile-hidden need-help">Need Help?</span>
        </div>
        <div class="cs-widget-body-right-content">
            <div class="cs-widget-button call-customer-care" tabindex="{{isActive ? '0' : '-1'}}"
                attr.aria-label="Call us at {{incontact_config.phone_no}}" role="button" (click)="callCustomerCare($event)" (keydown)="callCustomerCare($event)">
                <div class="cs-widget-padded-content">
                    <span class="mobile-only margined highlighted">Call Us</span>
                    <i class="fa fa-phone cs-icon-phone" aria-hidden="true"></i>
                    <span class="highlighted mobile-hidden">{{incontact_config.phone_no}}</span>
                </div>
            </div>
            <div class="cs-widget-button custom-quote" tabindex="{{isActive ? '0' : '-1'}}"
                 aria-label="Request a Custom Quote" role="button" (click)="redirectToPage('quotes'); $event.stopPropagation();" (keydown)="redirectToPage('quotes', $event);">
                <div>
                    <span class="regular-left mobile-hidden">Request a </span>
                    <i class="fa fa-quote-left cs-icon-quote-left"></i>
                    <span class="mobile-only highlighted">Custom Quote</span>
                    <span class="highlighted mobile-hidden">Custom Quote</span>
                    <i class="fa fa-quote-right cs-icon-quote-right"></i>
                </div>
            </div>
            <div class="cs-widget-hidden-button mobile-only cs-widget-button">
                <div>
                    <div class="close-container">
                        <span class="highlighted margined">Close</span>
                        <i class="fa fa-close"></i>
                    </div>
                    <div class="open-container">
                        <span class="highlighted">Need</span>
                        <span class="highlighted">Help</span>
                        <i class="fa fa-question-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
