import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '@dri/common/services/storage.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {UrlService} from '@dri/common/services/url.service';
import {CartCountService} from './../../service/cart-count-service';
import {FfrCartCountService} from './../../service/ffr-cart-count-service';
import {LoginRegisterBuilder} from '@dri/dri-login-register';
import {LoginService} from '@dri/customer/services/login.service';
import {LoginRegisterService} from './../../service/login-register.service';
import {Location} from '@angular/common';
import {CsrfService} from '@dri/common/services/csrf.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {CouponService} from '@dri/coupon/services/coupon.service';
import {AuthService} from '@dri/auth/services/auth.service';
import {CouponStorageService} from '@dri/coupon/services/coupon-storage.service';

@Component({
    selector: 'login-handler',
    templateUrl: 'login-handler.component.html',
})
export class LoginHandlerComponent {
    @Input() host : any;
    @Input() csrfToken: string;
    @Output() onCsrfToken: EventEmitter<string> = new EventEmitter();

    public form_data : any;
    public processing_request = false;
    public error_messages : any = [];
    public appConfig : any;
    public discountConfig: any;
    public storageKeys : any;
    public checkoutClientUrl : any;
    public loginErrorMessages : any = [];
    public loginRoute: any;
    public redirectUrl : any;
    public siteName : any;
    public is_login_page = false;
    private crossStorageConfig;
    private formName = 'login';

    constructor(
        private StorageService: StorageService,
        private ConfigManager : ConfigManagerService,
        private ActivatedRoute: ActivatedRoute,
        private UrlService : UrlService,
        private CartCountService : CartCountService,
        private FfrCartCountService : FfrCartCountService,
        private Router: Router,
        private LoginService : LoginService,
        private loginRegisterService : LoginRegisterService,
        private Location: Location,
        private CrossStorageClientService: CrossStorageClientService,
        private AuthService: AuthService,
        private Http: HttpClient,
        private CouponService: CouponService,
        private CsrfService: CsrfService,
        protected CouponStorageService: CouponStorageService,
        @Inject('LoginBuilder') protected Login: LoginRegisterBuilder
    ) {
        this.appConfig = this.ConfigManager.getConfig('app');
        this.discountConfig = this.ConfigManager.getConfig('discount');
        this.storageKeys = this.ConfigManager.getConfig('storage_keys');
        this.loginRoute = this.ConfigManager.getConfig('routes').checkout_client.login;
        this.checkoutClientUrl = this.appConfig.checkout_client_url;
        this.siteName = this.appConfig.site_name;
        // Get the query params
        this.ActivatedRoute.queryParams
            .subscribe((params) => {
                this.redirectUrl = params['url'] || this.ConfigManager.getConfig('routes').checkout_client.cart;
                if (this.loginRegisterService.params &&
                    this.loginRegisterService.params.redirect_url) {
                    this.redirectUrl = this.loginRegisterService.params.redirect_url;
                }
            });

        this.is_login_page = (this.Location.path().search(this.loginRoute) >= 0) ? true : false;
    }

    ngOnInit() {
        if (!this.csrfToken) {
            this.CsrfService.getCsrfToken(this.formName).subscribe((res) => {
                if (res['token']) {
                    this.csrfToken = res['token'];
                    this.onCsrfToken.emit(res['token']);
                }
            });
        }

        this.form_data = [
            {
                key: 'email',
                attributes: {
                    name: 'email',
                    type: 'email',
                    placeholder: 'Email',
                    maxlength: 50,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'email': true,
                    'required': true,
                },
                error_message: {
                    'email': 'Invalid Email.',
                    'required': 'Email is required.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
            {
                key: 'password',
                attributes: {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Password',
                    maxlength: 50,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'required': true,
                },
                error_message: {
                    'required': 'Password is required.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
        ];
    }

    login() {
        if (!this.Login.checkValidity()) {
            return false;
        }

        // Just in case csrf token ajax is delayed
        if (!this.csrfToken) {
            return false;
        }

        if (!this.CsrfService.validateCsrfToken(this.csrfToken)) {
            this.loginErrorMessages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
            return false;
        }

        this.processing_request = true;
        this.error_messages = [];
        this.loginErrorMessages = [];
        this.LoginService.login(
            this.Login.form_inputs.email,
            this.Login.form_inputs.password,
            true,
            this.csrfToken,
            this.formName
        ).subscribe(
            (response) => {
                this.loginHandler(response);
                this.processing_request = false;
            },
            (HttpErrorResponse) => {
                this.processing_request = false;
                if (HttpErrorResponse.error.detail) {
                    if (HttpErrorResponse.error.detail.hasOwnProperty('csrf')) {
                        this.loginErrorMessages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
                    } else {
                        this.error_messages['detail'] = HttpErrorResponse.error.detail;
                        this.error_messages['status'] = HttpErrorResponse.error.status;
                        this.errorHandler(this.error_messages);
                    }
                }
            }
        );
    }

    errorHandler(data) {
        this.loginErrorMessages = [];

        if (data['status'] == 401 || data['status'] == 412 || data['status'] == 400) {
            this.loginErrorMessages = ['Invalid Login Details'];
        } else {
            this.loginErrorMessages = [data.detail];
        }
    }

    loginHandler(response) {
        // save customer data
        if (typeof response != 'undefined') {
            if (response['cart_id']) {
                this.setCart(this.storageKeys.cart_id, response['cart_id']);
                this.CartCountService.setCartCount(response['cart_count']);
            }

            if (response['ffr_cart_id']) {
                this.setCart(this.storageKeys.ffr_cart_id, response['ffr_cart_id']);
                this.FfrCartCountService.setCartCount(response['ffr_cart_count']);
            }

            if (typeof response['customer_data'] != 'undefined') {
                const customerDataField = this.ConfigManager.getConfig('customer').customer_data_field;
                this.crossStorageConfig = this.ConfigManager.getConfig('cross_storage');

                this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
                this.CrossStorageClientService.setData(customerDataField, JSON.stringify(response['customer_data']));


                if ((this.discountConfig['coupon_banner'] && this.discountConfig['coupon_banner']['enable_partner_coupon']) && parseInt(response['customer_data']['customer_coupon_id'])) {
                    const _this = this;

                    this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
                    const couponFromStorageData = this.CouponStorageService.getCoupon();
                    // if there is no coupon in storage, set customer coupon
                    if (!couponFromStorageData) {
                        _this.CouponService.validateCouponIdWithBasic(response['customer_data']['customer_coupon_id']).subscribe(
                            (couponDetails) => {
                                if (typeof couponDetails['cp_storage_data'] !== 'undefined' && couponDetails['cp_storage_data'] !== null) {
                                    this.CouponStorageService.setCoupon(couponDetails['cp_storage_data']);
                                    this.CouponStorageService.emitData(couponDetails['cp_storage_data']);

                                    if (typeof couponDetails['coupon_code'] !== 'undefined' && couponDetails['coupon_code'] !== null) {
                                        const customerStorageData = response['customer_data'];
                                        customerStorageData.customer_coupon_code = couponDetails['coupon_code'];
                                        _this.CrossStorageClientService.setData(customerDataField, JSON.stringify(customerStorageData)).then(function() {
                                            _this.AuthService.login_source.next(response);
                                            _this.loginRedirection(response);
                                        });
                                    }
                                } else {
                                    _this.AuthService.login_source.next(response);
                                    _this.loginRedirection(response);
                                }
                            },
                            (error) => {
                                _this.AuthService.login_source.next(response);
                                _this.loginRedirection(response);
                            }
                        );
                    } else {
                        _this.AuthService.login_source.next(response);
                        _this.loginRedirection(response);
                    }
                } else {
                    this.AuthService.login_source.next(response);
                    this.loginRedirection(response);
                }
            }

            this.CouponStorageService.partnerCouponSource.next();
        } else {
            this.loginRedirection(response);
        }
    }

    public setCart(key, cartId) {
        const appConfig = this.ConfigManager.getConfig('app');
        const storage_option = {
            path: '/',
            domain: '.' + appConfig.domain,
        };
        this.StorageService.setItem(key, cartId, storage_option);
    }

    public validateCustomerCouponId(customer_coupon_id) {
        const app_config = this.ConfigManager.getConfig('app');
        const couponConfig = this.ConfigManager.getConfig('coupon');
        const endPoint = app_config.checkout_api_url + couponConfig.validate_coupon_code + customer_coupon_id +
                        '?website_code=' + app_config.website_code;

        return this.Http.get(endPoint);
    }

    public parseJsonString(str: string) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }

    public loginRedirection(response) {
        if (this.is_login_page) {
            if (this.host.modal) {
                this.loginRegisterService.hideModal();
            }

            window.location.href = this.redirectUrl;
        } else {
            this.loginRegisterService.setLoginDetails(response);
        }
    }

    switchForm(form) {
        this.host.switchForm(form);
    }
}
