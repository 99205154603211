import {Component, Input, OnInit} from '@angular/core';
import {ErrorMessagesComponent} from '@dri/common/components/error-messages.component';

@Component({
    selector: 'app-error-messages',
    templateUrl: 'app-error-messages.component.html',
})

export class AppErrorMessagesComponent extends ErrorMessagesComponent {

}
