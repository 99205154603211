import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AdManagerService} from '@dri/common/services/admanager.service';
import {SeoService} from '@dri/common/services/seo.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {of} from 'rxjs';

@Injectable()
export class AdmanagerResolver implements Resolve<any> {
	private marketingConfig;

	constructor(
		private ConfigManagerService: ConfigManagerService,
		private AdmanagerService: AdManagerService,
		private SeoService: SeoService
	) {
	    this.marketingConfig = this.ConfigManagerService.getConfig('marketing');
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	    this.AdmanagerService.setCustomerDevice().subscribe((resp) => {
	        return this.callAdm(state);
	    }, (error) => {
	        return this.callAdm(state);
	    });
	}

	public callAdm(state) {
	    let queryParams = state.root.queryParams;
	    this.AdmanagerService.setLocalStorageToCookie().then((response) => {
	        return new Promise((resolve, reject) => {
	            if (this.marketingConfig.seo.enabled) {
	                queryParams = this.SeoService.getSeoParameter(queryParams);
	            }

	            if (this.marketingConfig.admanager.enabled && this.AdmanagerService.isValidParams(queryParams)) {
	                this.AdmanagerService.setAdManagerUrlParam(queryParams).subscribe((response) => {
	                    if (response['success'] === true) {
	                        resolve(true);
	                    }
	                });
	            } else {
	                resolve(true);
	            }
	        });
	    });
	}
}
