import {Component, Input, Inject, Output, EventEmitter, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {StorageService} from '@dri/common/services/storage.service';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {UrlService} from '@dri/common/services/url.service';
import {CartCountService} from './../../service/cart-count-service';
import {FfrCartCountService} from './../../service/ffr-cart-count-service';
import {LoginRegisterBuilder} from '@dri/dri-login-register';
import {RegisterService} from '@dri/customer/services/register.service';
import {LoginRegisterService} from './../../service/login-register.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {CsrfService} from '@dri/common/services/csrf.service';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {DataLayerService} from '@dri/common/services/datalayer.service';

@Component({
    selector: 'register-handler',
    templateUrl: 'register-handler.component.html',
})
export class RegisterHandlerComponent implements OnInit {
    @Input() host : any;
    @Input() csrfToken: string;
    @Output() onCsrfToken: EventEmitter<string> = new EventEmitter();

    public form_data : any;
    public processing_request = false;
    public error_messages : any = [];
    public appConfig : any;
    public storageKeys : any;
    public checkoutClientUrl : any;
    public registerErrorMessages : any = [];
    public redirectUrl : any;
    public siteUrl:any;
    public siteName:any;
    public termsUrl:any;
    public privacyUrl:any;
    public is_login_page = false;
    public loginRoute:any;
    private crossStorageConfig;
    private formName = 'register';

    constructor(
        private StorageService: StorageService,
        private ConfigManagerService : ConfigManagerService,
        private ActivatedRoute: ActivatedRoute,
        private UrlService : UrlService,
        private CartCountService : CartCountService,
        private FfrCartCountService : FfrCartCountService,
        private Router: Router,
        private RegisterService : RegisterService,
        private loginRegisterService : LoginRegisterService,
        private DeviceDetectorService : DeviceDetectorService,
        private Location: Location,
        private CsrfService: CsrfService,
        private CrossStorageClientService: CrossStorageClientService,
        private DataLayerService: DataLayerService,
        @Inject('RegisterBuilder') protected Register: LoginRegisterBuilder
    ) {
        this.appConfig = this.ConfigManagerService.getConfig('app');
        this.storageKeys = this.ConfigManagerService.getConfig('storage_keys');
        this.loginRoute = this.ConfigManagerService.getConfig('routes').checkout_client.login;
        this.checkoutClientUrl = this.appConfig.checkout_client_url;
        this.siteName = this.appConfig.site_name;
        this.siteUrl = this.appConfig.site_url;
        this.termsUrl = this.appConfig.urls.terms_and_condition;
        this.privacyUrl = this.appConfig.urls.privacy_url;

        this.ActivatedRoute.queryParams
            .subscribe((params) => {
                this.redirectUrl = params['url'] || this.ConfigManagerService.getConfig('routes').checkout_client.cart;
                if (this.loginRegisterService.params &&
                    this.loginRegisterService.params.redirect_url) {
                    this.redirectUrl = this.loginRegisterService.params.redirect_url;
                }
            });

        this.is_login_page = (this.Location.path().search(this.loginRoute) >= 0) ? true : false;
    }

    ngOnInit() {
        if (!this.csrfToken) {
            this.CsrfService.getCsrfToken(this.formName).subscribe((res) => {
                if (res['token']) {
                    this.csrfToken = res['token'];
                    this.onCsrfToken.emit(res['token']);
                }
            });
        }

        this.form_data = [
            {
                key: 'first_name',
                attributes: {
                    name: 'first_name',
                    type: 'text',
                    placeholder: 'First Name',
                    maxlength: 20,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'required': true,
                    'name': true,
                },
                error_message: {
                    'required': 'First name is required.',
                    'name': 'Please provide a valid first name.',
                },
                bootstrap: true,
                custom_class: 'col-sm-12 col-xs-12 side-by-side left site-form-group',
            },
            {
                key: 'last_name',
                attributes: {
                    name: 'last_name',
                    type: 'text',
                    placeholder: 'Last Name',
                    maxlength: 20,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'required': true,
                    'name': true,
                },
                error_message: {
                    'required': 'Last name is required.',
                    'name': 'Please provide a valid last name.',
                },
                bootstrap: true,
                custom_class: 'col-sm-12 col-xs-12 side-by-side right site-form-group',
            },
            {
                key: 'email',
                attributes: {
                    name: 'email',
                    type: 'email',
                    placeholder: 'Email',
                    maxlength: 50,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'email': true,
                    'required': true,
                },
                error_message: {
                    'email': 'Invalid Email.',
                    'required': 'Email is required.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
            {
                key: 'password',
                attributes: {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Create Password',
                    maxlength: 50,
                    autocomplete: 'off',
                    required: true,
                },
                events: ['change'],
                validations: {
                    'required': true,
                    'min': 6,
                },
                error_message: {
                    'required': 'Password is required.',
                    'min': 'Password should be at least 6 characters long.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
            {
                key: 'phone',
                attributes: {
                    name: 'phone',
                    type: 'text',
                    placeholder: 'Phone Number*',
                    maxlength: 14,
                    autocomplete: 'off',
                    required: true,
                },
                filters: ['phone'],
                events: ['change'],
                validations: {
                    'required': true,
                    'min': 14,
                },
                error_message: {
                    'required': 'Phone is required.',
                    'min': 'Invalid phone number.',
                },
                bootstrap: true,
                custom_class: 'site-form-group',
            },
            {
                key: 'email_address',
                attributes: {
                    name: 'email_address',
                    type: 'text',
                    placeholder: 'Email Address',
                    autocomplete: 'off',
                    required: false,
                    id: 'email_address',
                },
                validations: {
                    'required': false,
                },
                bootstrap: true,
                custom_class: 'hidden',
            },
        ];
    }

    public register() {
        if (!this.Register.checkValidity()) {
            return false;
        }

        // Just in case csrf token ajax is delayed
        if (!this.csrfToken) {
            return false;
        }

        if (!this.CsrfService.validateCsrfToken(this.csrfToken)) {
            this.registerErrorMessages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
            return false;
        }

        // dummy email address field to be used for reducing spams
        const emailAddress:any = document.getElementById('email_address');
        if (emailAddress.value != '') {
            this.registerErrorMessages = ['Cannot create account. Please reach out to our Customer Service.'];
            return false;
        }

        this.registerErrorMessages = [];

        let registrationDevice = 'desktop';

        if (this.DeviceDetectorService.isMobile()) {
            registrationDevice = 'mobile';
        } else if (this.DeviceDetectorService.isTablet()) {
            registrationDevice = 'tablet';
        }

        const registration_params = JSON.parse(JSON.stringify(this.Register.form_inputs));
        registration_params.registration_device = registrationDevice;
        registration_params.form_name = this.formName;
        registration_params.csrf_token = this.csrfToken;

        this.processing_request = true;
        this.error_messages = [];
        const registerFormValues = {...registration_params};

        this.RegisterService.register(registerFormValues).subscribe(
            (response) => {
                this.processing_request= false;
                this.registerHandler(response);
            },
            (HttpErrorResponse) => {
                let isEmailExistsError = false;
                if (HttpErrorResponse.error.status == 412) {
                    if (HttpErrorResponse.error.detail.hasOwnProperty('email')) {
                        if (HttpErrorResponse.error.detail['email'].emailExists) {
                            const email_message = 'Email ' + this.Register.form_inputs['email'] + ' is already in use.';
                            this.Register.setInputError('email', this.Register.form_inputs['email'], email_message);
                            isEmailExistsError = true;
                        } else {
                            const email_message = 'Please provide a valid email address.';
                            this.Register.setInputError('email', this.Register.form_inputs['email'], email_message);
                            this.error_messages = ['Invalid Registration Details'];
                        }
                    }

                    if (HttpErrorResponse.error.detail.hasOwnProperty('first_name') && HttpErrorResponse.error.detail['first_name'].regexNotMatch) {
                        this.Register.setInputError('first_name', this.Register.form_inputs['first_name'], HttpErrorResponse.error.detail['first_name'].regexNotMatch);
                    }

                    if (HttpErrorResponse.error.detail.hasOwnProperty('last_name') && HttpErrorResponse.error.detail['last_name'].regexNotMatch) {
                        this.Register.setInputError('last_name', this.Register.form_inputs['last_name'], HttpErrorResponse.error.detail['last_name'].regexNotMatch);
                    }

                    if (HttpErrorResponse.error.detail.hasOwnProperty('csrf')) {
                        this.error_messages = [this.CsrfService.INVALID_TOKEN_MESSAGE];
                    }
                } else {
                    this.error_messages = ['Invalid Registration Details'];
                }

                this.processing_request= false;
                this.errorHandler(this.error_messages);

                this.DataLayerService.logDataLayerEvent({
                    event: 'create_account',
                    customer_id: null,
                    is_success: isEmailExistsError ? 'already exists' : 'no',
                });
            }
        );
    }

    public parseError(error) {
        let error_message = '';
        for (const i in error) {
            error_message = error[i];
        }
        return error_message;
    }

    errorHandler(data) {
        this.registerErrorMessages = [];
        this.registerErrorMessages = data;
    }

    registerHandler(response) {
        // save customer data
        // eslint-disable-next-line no-constant-condition
        if (typeof response) {
            this.DataLayerService.logDataLayerEvent({
                event: 'create_account',
                customer_id: response['customer_id'],
                is_success: 'yes',
            });

            this.StorageService.setItem(
                this.ConfigManagerService.getConfig('customer').customer_data_field,
                JSON.stringify(response),
                {
                    path: '/',
                    domain: '.' + this.appConfig.domain,
                    expires: moment((moment().unix() + 60 * 60 * 24 * 30 * 120) * 1000).format('ddd, DD MMM YYYY HH:mm:ss'),
                }
            );

            if (response['cart_id']) {
                this.setCart(this.storageKeys.cart_id, response['cart_id']);
                this.CartCountService.setCartCount(response['cart_count']);
            }

            if (response['ffr_cart_id']) {
                this.setCart(this.storageKeys.ffr_cart_id, response['ffr_cart_id']);
                this.FfrCartCountService.setCartCount(response['ffr_cart_count']);
            }

            if (typeof response['customer_data'] != 'undefined') {
                const customerDataField = this.ConfigManagerService.getConfig('customer').customer_data_field;
                this.crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');

                this.CrossStorageClientService.onInit(this.crossStorageConfig.hub_url, this.crossStorageConfig.hub_opt);
                this.CrossStorageClientService.setData(customerDataField, JSON.stringify(response['customer_data']));
            }
        }

        if (this.is_login_page) {
            if (this.host.modal) {
                this.loginRegisterService.hideModal();
            }

            window.location.href = this.redirectUrl;
        } else {
            this.loginRegisterService.setLoginDetails(response);
        }
    }

    openPopUp(url) {
        window.open(this.siteUrl + url, '_blank', 'width=600,height=600,resizable,scrollbars,status');
    }

    public setCart(key, cartId) {
        const appConfig = this.ConfigManagerService.getConfig('app');
        const storage_option = {
            path: '/',
            domain: '.' + appConfig.domain,
        };
        this.StorageService.setItem(key, cartId, storage_option);
    }

    switchForm(form) {
        this.host.switchForm(form);
    }
}
