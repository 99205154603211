import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthHttpInterceptor} from '@dri/auth/interceptors/auth-http.interceptor';

import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CookieModule} from 'ngx-cookie';
import {HttpClientModule} from '@angular/common/http';

import {AuthModule} from '@dri/auth/auth.module';
import {DriCommonModule} from '@dri/common/common.module';
import {DriDesignModule} from '@dri/design/design.module';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APP_CONFIG} from '../environments/environment';
import {AgmCoreModule} from '@agm/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {WebsiteSwitcherInterceptor} from './core/interceptor/website-switcher.interceptor';
import {NotFoundModule} from './not-found/not-found.module';
import {CsrfInterceptor} from './core/interceptor/csrf.interceptor';
import {RecomputeInterceptor} from './core/interceptor/recompute.interceptor';
import {RouterModule} from '@angular/router';
import {StubInterceptor} from './core/interceptor/stub-interceptor';
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        CookieModule.forRoot(),
        CoreModule,
        AppRoutingModule,
        AuthModule,
        HttpClientModule,
        DriCommonModule.forRoot(APP_CONFIG),
        DriDesignModule,
        AgmCoreModule.forRoot({
            apiKey: APP_CONFIG.app.google_api.key,
            libraries: APP_CONFIG.app.google_api.libraries,
        }),
        NotFoundModule,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WebsiteSwitcherInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RecomputeInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: StubInterceptor,
            multi: true,
        },
    ],
})
export class AppModule { }
