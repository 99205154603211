import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CsrfInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let csrfToken = '';
        if (request.method == 'GET' && request.params.get('csrf_token')) {
            csrfToken = request.params.get('csrf_token');
            request.params.delete('csrf_token');
        } else if (request.body && request.body.hasOwnProperty('csrf_token')) {
            csrfToken = request.body.csrf_token;
            delete request.body.csrf_token;
        }

        if (csrfToken) {
            const req = request.clone({
                setHeaders: {
                    'X-XSRF-TOKEN': csrfToken,
                },
            });

            return next.handle(req);
        }

        return next.handle(request);
    }
}
