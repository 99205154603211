import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'login-register-modal',
    template: `
   <div class="login-register-modal-container">
       <div class="modal-header">
           <button (click)="hide()" aria-label="Close" class="modal-close-btn close pull-right" type="button">
               <span aria-hidden="true" class="fehp-close"></span>
           </button>
           <h4 class="modal-title" *ngIf="form_type === 'login'">Log In</h4>
           <h4 class="modal-title" *ngIf="form_type === 'forgot_password'">Forgot Password</h4>
           <h4 class="modal-title" *ngIf="form_type === 'register'">First time here?</h4>
       </div>
       <div class="modal-body">
           <login-register-host [form_type]="form_type"  (onFormType)="form_type = $event"></login-register-host>
       </div>
   </div>
  `,
})
export class LoginRegisterModalComponent {
    @Input() form_type : any = 'login';
    @Output() onFormType: EventEmitter<string> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) {}

    hide() {
        this.bsModalRef.hide();
    }
}
