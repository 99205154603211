import {Component, OnInit} from '@angular/core';
import {PageBackdropService} from './../service/page-backdrop.service';
import {HeaderService} from '../service/header.service';

@Component({
    selector: 'page-backdrop',
    templateUrl: 'page-backdrop.component.html',
})

export class PageBackdropComponent {
    public showBackdrop = false;
    private tap: any;
    constructor(
        private pageBackdropService: PageBackdropService,
        private headerService: HeaderService
    ) {
        this.tap = ('ontouchstart' in document.documentElement);
        this.pageBackdropService.showBackDropFlag.subscribe(
            (data) => {
                this.showBackdrop = data;
            }
        );
    }

    public hideBackdrop() {
        if (this.tap) {
            this.showBackdrop = false;
            this.headerService.onBackdropEvent(false);
        }
    }
}
