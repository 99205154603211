import {AfterViewInit, Component} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {Router, NavigationEnd} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'cs-widget',
    templateUrl: 'cs-widget.component.html',
})

export class CsWidgetComponent implements AfterViewInit {
    public app_config: any;
    public siteConfig: any;
    public incontact_config: any;
    public show_button = true;
    public isActive = false;
    public csWidgetContainer: HTMLElement;

    constructor(
        protected Router : Router,
        private Location: Location,
        protected ConfigManagerService: ConfigManagerService
    ) {
        this.app_config = this.ConfigManagerService.getConfig('app');
        this.siteConfig = this.ConfigManagerService.getConfig('site');
        this.incontact_config = this.ConfigManagerService.getConfig('incontact');

        this.setButtonVisibility();
        this.Router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setButtonVisibility();
            }
        });
    }

    ngAfterViewInit() {
        this.csWidgetContainer = document.getElementById('cs_widget_container');
    }

    toggleInContact(event) {
        event.stopPropagation();
        if (this.isActive) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }
    }

    toggleCSWidgetOnKeyDown(event) {
        const allowedKeyCodes = [9, 13, 27]; // [Tab, Enter, Esc]
        const allowedKeyCodesToShowElement = [13]; // [Enter]
        const allowedKeyCodesToHideElement = [13, 27]; // [Enter, Esc]

        const isKeyCodeAllowed = allowedKeyCodes.indexOf(event.keyCode) != -1;
        const isKeyCodeAllowedToShowElement = allowedKeyCodesToShowElement.indexOf(event.keyCode) != -1;
        const isKeyCodeAllowedToHideElement = allowedKeyCodesToHideElement.indexOf(event.keyCode) != -1;

        if (isKeyCodeAllowed) {
            if (this.isActive && isKeyCodeAllowedToHideElement) {
                this.isActive = false;
            } else if (!this.isActive && isKeyCodeAllowedToShowElement) {
                this.isActive = true;
            }
        }
    }

    hideCSWidgetOnFocusOut(event) {
        const isChild = this.csWidgetContainer.contains(event.relatedTarget);

        if (!isChild) {
            this.isActive = false;
        }
    }

    callCustomerCare(event) {
        event.stopPropagation();
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        location.href = 'tel:+' + this.siteConfig.phone_no;
    }

    redirectToPage(url, event?) {
        if (event && event.type == 'keydown' && event.keyCode != 13) { // event.keyCode != Enter
            return;
        }

        location.href = this.app_config.submodule_client_url + '/' + url;
    }

    setButtonVisibility() {
        this.show_button = true;
    }
}
