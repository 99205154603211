import {AuthService} from '@dri/auth/services/auth.service';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, NavigationStart} from '@angular/router';
import {CustomerUtmService} from '@dri/customer/services/customer-utm.service';
import {VisitorIdLib} from '@dri/visitor-id-lib/src/visitor-id-lib';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {EnhancedEcommerceLib} from '@dri/enhanced-ecommerce-lib/src/enhanced-ecommerce-lib';
import {CrossStorageClientService} from '@dri/common/services/cross-storage-client.service';
import {RefreshTokenModel} from '@dri/auth/models/refresh-token.model';

declare let page_data: any;

@Component({
    selector: 'app',
    template: `
        <app-header></app-header>
        <div *ngIf="route_content_is_not_loaded" class="comp-page-content-filler"></div>
        <div class="page-content-container" id="main_content" tabindex="0">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    `,
    providers: [CustomerUtmService],
})
export class AppComponent implements OnInit {
    private config: any;
    public route_content_is_not_loaded: boolean;
    private trackerApiLoaded:boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private CustomerUtmService : CustomerUtmService,
        private AuthService: AuthService,
        private RefreshTokenModel: RefreshTokenModel,
        private ConfigManagerService: ConfigManagerService,
        protected CrossStorageClientService: CrossStorageClientService,
        @Inject('TrackerApiService') protected VisitorIdLib: VisitorIdLib,
        @Inject('EnhancedEcommerceLib') protected EnhancedEcommerceLib: EnhancedEcommerceLib
    ) {
        this.config = this.ConfigManagerService.getAllConfig();
        this.route_content_is_not_loaded = true;
        this.VisitorIdLib.setVisitorIdKey(this.ConfigManagerService.getConfig('storage_keys').visitor_id);
        const crossStorageConfig = this.ConfigManagerService.getConfig('cross_storage');
        this.CrossStorageClientService.onInit(crossStorageConfig.hub_url, crossStorageConfig.hub_opt);

        // Set Identifier that refresh token is existing
        if (window['blnRfToken']) {
            this.RefreshTokenModel.blnRfToken = true;
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!this.trackerApiLoaded) {
                    this.VisitorIdLib.loadTrackerApi(
                        this.config.app.website_code,
                        this.config.app.tracker_domain,
                        this.config.app.tracker_domain_file
                    ).then(() => {
                        this.trackerApiLoaded = true;
                        this.VisitorIdLib.setTrackerApi(this.config.app.domain, this.config.app.website_code, '', '1');
                    }).catch(() => {
                        console.error('Encountered an error while loading Tracker API');
                    });
                } else {
                    this.VisitorIdLib.setTrackerApi(this.config.app.domain, this.config.app.website_code, '', '1');
                }

                const inContactConfig = this.config.incontact;
                document.querySelector('body').classList.remove('hide-live-chat');
                if (inContactConfig.excluded_page.indexOf(event.url.split('?')[0]) !== -1 || /customer\/login/i.test(event.url)) {
                    document.querySelector('body').classList.add('hide-live-chat');
                }

                // Enable this when disabling Tracker API
                // this.VisitorIdLib.setVisitorId(APP_CONFIG.app.domain, APP_CONFIG.app.website_code + "_", null);
            }

            if (event instanceof NavigationEnd) {
                this.route_content_is_not_loaded = false;
                // this.route.queryParams.subscribe( params => this.CustomerUtmService.createCustomerUtm(params) );

                // trigger page view
                this.triggerPageView();

                // trigger customer datalayer
                this.pushCustomerType();

                this.setGlobalPageData();

                if (typeof window['ABTest'] != 'undefined') {
                    window['ABTest'].setStorageService(this.CrossStorageClientService.CrossStorageModel);
                    window['ABTest'].filterJobOrderExperiments();
                }
            }
        });

        AuthService.logged_in$.subscribe(
            (accessToken) => {
                const accessTokenData = this.AuthService.parseAccessTokenData();
                const customer_info = accessTokenData.data;
                this.pushCustomerType(customer_info);
            }
        );
        AuthService.logged_out$.subscribe(
            (data) => {
                const customer_info = {};
                this.pushCustomerType(customer_info);
            }
        );
    }

    ngOnInit() {
        this.AuthService.requireLogin$.subscribe((params) => {
            this.router.navigate([
                this.config.routes.checkout_client.login,
            ], {
                queryParams: params,
            });
        });
    }

    private setGlobalPageData() {
        let childRoute = this.route.firstChild;
        let pageGroupName = null;

        while (childRoute) {
            if (childRoute.firstChild) {
                childRoute = childRoute.firstChild;
            } else if (childRoute.snapshot.data && childRoute.snapshot.data['page_group_name']) {
                pageGroupName = childRoute.snapshot.data['page_group_name'];
                break;
            } else {
                pageGroupName = this.config.app.partition_name;
                break;
            }
        }

        if (pageGroupName) {
            page_data['page_group_name'] = pageGroupName;
        }
    }

    public pushCustomerType(customer_info = null) {
        let customerInfo = customer_info;
        if (customer_info === null && this.AuthService.parseAccessTokenData()) {
            const accessTokenData = this.AuthService.parseAccessTokenData();
            customerInfo = accessTokenData.data;
        }

        if (customerInfo) {
            const customerData = {
                customerId: customerInfo['customer_id'],
                customerType: customerInfo['customer_type'],
            };
            this.EnhancedEcommerceLib.pushData(customerData);
        } else {
            const customerData = {
                customerType: 'NOT_LOGGED_IN',
            };
            this.EnhancedEcommerceLib.pushData(customerData);
        }
    }

    private triggerPageView() {
        this.EnhancedEcommerceLib.pushData({
            event: 'angular_framework_page_view',
        });
    }
}

