import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '@dri/auth/services/auth-guard.service';
import {UrlCouponResolver} from './core/resolver/url-coupon-resolver';
import {NotFoundComponent} from './not-found/components/not-found.component';
import {AdmanagerResolver} from './core/resolver/admanager-resolver';

const routes: Routes = [
    {
        path: '',
        resolve: {url_coupon: UrlCouponResolver, admanager: AdmanagerResolver},
        children: [
            {
                path: '',
                redirectTo: 'cart',
                pathMatch: 'full',
            },
            {
                path: 'customer',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/customer/customer.module').then((m) => m.AppCustomerModule),
                canActivate: [AuthGuardService],
            },
            {
                path: 'cart',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/cart/cart.module').then((m) => m.AppCartModule),
                canActivate: [AuthGuardService],
                data: {
                    page_group_name: 'Cart',
                    has_newsletter: 'n',
                },
            },
            {
                path: 'ffr',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/ffr/ffr.module').then((m) => m.AppFfrModule),
                canActivate: [AuthGuardService],
            },
            {
                path: 'upload',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/upload/upload.module').then((m) => m.AppUploadModule),
                canActivate: [AuthGuardService],
                data: {
                    page_group_name: 'Upload',
                },
            },
            {
                path: 'shipping',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/shipping/shipping.module').then((m) => m.AppShippingModule),
                canActivate: [AuthGuardService],
                data: {
                    has_newsletter: 'n',
                },
            },
            {
                path: 'payment',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/payment/payment.module').then((m) => m.AppPaymentModule),
                canActivate: [AuthGuardService],
                data: {
                    has_newsletter: 'n',
                },
            },
            {
                path: 'billing',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/billing/billing.module').then((m) => m.AppBillingModule),
                canActivate: [AuthGuardService],
                data: {
                    has_newsletter: 'n',
                },
            },
            {
                path: 'confirmation',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/confirmation/confirmation.module').then((m) => m.AppConfirmationModule),
                canActivate: [AuthGuardService],
            },
            {
                path: 'design',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/design/design.module').then((m) => m.AppDesignModule),
                canActivate: [AuthGuardService],
            },
            {
                path: 'mailing',
                resolve: {url_coupon: UrlCouponResolver},
                loadChildren: () => import('app/mailing/mailing.module').then((m) => m.AppMailingModule),
                canActivate: [AuthGuardService],
            },
            {
                path: '**',
                component: NotFoundComponent,
                canActivate: [],
                resolve: {},
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
