import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {StorageService} from '@dri/common/services/storage.service';
import {PageDiscountDisplayService} from '../service/page-discount-display-service';
import {CouponStorageService} from '@dri/coupon/services/coupon-storage.service';
@Injectable()
export class UrlCouponResolver implements Resolve<any> {
    private storage;

    constructor(
        private Http: HttpClient,
        private ConfigManagerService: ConfigManagerService,
        private StorageService: StorageService,
        private Router: Router,
        private route: ActivatedRoute,
        private PageDiscountDisplayService: PageDiscountDisplayService,
        private CouponStorageService: CouponStorageService
    ) {
    }


    /**
     * Use for applying coupon via Url
     */
    public setCouponViaUrl(coupon_code : string) {
        const app_config = this.ConfigManagerService.getConfig('app');
        const couponConfig = this.ConfigManagerService.getConfig('coupon');
        const endPoint = app_config.checkout_api_url + couponConfig.validate_coupon_code + coupon_code +
            '?website_code=' + app_config.website_code;

        return this.Http.get(endPoint);
    }

    public parseJsonString(str: string) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const discountConfig = this.ConfigManagerService.getConfig('discount');
        const couponStorageName = this.ConfigManagerService.getConfig('coupon').storage_name;
        const queryParams = state.root.queryParams;
        const couponFromUrl = queryParams[couponStorageName];

        const _this = this;

        return new Promise((resolve, reject) => {
            if (!discountConfig.enable_coupon_via_url) {
                resolve(true);
                return true;
            }

            const couponFromStorageData = _this.CouponStorageService.getCoupon();

            // check if coupon from url is already stored in local storage
            let urlCouponIsInStorage = false;
            for (const i in couponFromStorageData) {
                if (couponFromStorageData[i].indexOf(couponFromUrl) >= 0) {
                    urlCouponIsInStorage = true;
                    break;
                }
            }

            // validate and set couponFromUrl to coupon storage if the ff. are met:
            // 1. coupon from url exists
            // 2. on page load
            // 3. coupon from url does not exist in coupon storage yet
            if (typeof couponFromUrl !== 'undefined' && !urlCouponIsInStorage) {
                return _this.setCouponViaUrl(couponFromUrl).subscribe((response) => {
                    if (response['success'] === true) {
                        _this.CouponStorageService.setCoupon(response['cp_storage_data']);
                        _this.PageDiscountDisplayService.setPageDiscountDisplayData(couponFromUrl);
                    } else {
                        _this.PageDiscountDisplayService.setPageDiscountDisplayData(null);
                    }

                    resolve(response);
                });
            } else {
                _this.PageDiscountDisplayService.setPageDiscountDisplayData(null);
                resolve(true);
            }
        });
    }
}
