import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CartPriceModel {
    public sub_total = 0;
    public tax = 0;
    public discount = 0;
    public shipping_total = 0;
    public total_amount = 0;

    setData(data) {
        Object.keys(this).forEach( (key) => {
            if (key in data) {
                this[key] = data[key];
            }
        });
        return this;
    }

    getData() {
        return JSON.parse(JSON.stringify(this));
    }
}
