<div class="comp-login-handler-container">
    <div class="heading-container">
        <h2 class="header-title">Log In</h2>
    </div>
	<div class="error-messages-container">
		<app-error-messages [error_messages]='loginErrorMessages'></app-error-messages>
	</div>
	<form (ngSubmit)="login()"
	      novalidate
	      autocomplete="off"
	      loginForm
	      [form_data]="form_data">
		<div class="form-group forgot-password-link-wrapper">
            <p>
                <a (click)="switchForm('forgot_password')" data-qaid="forgotPasswordLink">
                    Forgot password?
                </a>
            </p>
		</div>
		<div class="form-group">
			<div class="btn-container">
				<button type="submit"
				        class="site-btn-primary login-submit-btn btn-lg"
				        data-qaid="loginSubmitButton"
				        focus-out
				        [disabled]="processing_request">
				    <span *ngIf="!processing_request; else processing">LOG IN</span>
				    <ng-template #processing>
				        <span>
				            <i class="fa fa-spinner fa-spin"></i> Logging in...
				        </span>
				    </ng-template>
				</button>
			</div>
		</div>
		<div class="form-group link-register">
			<h5><span>New to 48HourPrint?</span></h5>
		</div>
		<div class="form-group link-register-wrapper">
			<a class="form-tab-pane-trigger btn-lg btn-block login-reg-link reg-link btn-create-account-link site-btn-tertiary" (click)="switchForm('register')" data-no-redirect="1" data-qaid="noReturning">CREATE MY ACCOUNT</a>
		</div>
	</form>
</div>
