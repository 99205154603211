import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router, UrlSerializer} from '@angular/router';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StubInterceptor implements HttpInterceptor {
    private stubPageEndpointKeyMapping = {
        shipping: {
            'customer-address': 'customer_address',
            'secured-compute-cart': 'cart_document',
            'pre-process-shipping': 'pre_process_shipping',
            'cart-shipping-rates': 'cart_shipping_rates',
            'shipping-rates-cache': 'shipping_rates_cache',
            'pickup-locations': 'pickup_locations',
            'cart-freight-shipping-rates': 'freight_rates',
            'shipping-tax-rate': 'shipping_tax_rate',
            'shipping-rates': 'shipping_rates',
            'item-level-shipping-rates-v2': 'item_level_shipping_rates_v2',
            'website-products-discount-data': 'website_products_discount_data',
        },
        cart: {
            'secured-compute-cart': 'cart_document',
            'apply-coupon': 'apply_coupon',
            'compute-order': 'order_document',
            'shipping-tax-rate': 'shipping_tax_rate',
            'pre-process-shipping': 'pre_process_shipping',
            'order-shipping-rates': 'order_shipping_rates',
            'shipping-rates-cache': 'shipping_rates_cache',
        },
        payment: {
            'compute-order': 'order_document',
            'terms-payment': 'terms',
            'chase-payment-profile': 'payment_profiles',
            'chase-payment': 'new_payment_profile',
            'order-store-credit-payment': 'order_store_credit_payment',
        },
        confirmation: {
            'confirmation': 'confirmation',
        },
    };
    constructor(
        protected ConfigManager: ConfigManagerService
    ) {
    }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // do nothing if not local env
        if (this.ConfigManager.getConfig('env') !== 'local') {
            return next.handle(request);
        }
        const parameters = new URLSearchParams(window.location.search);
        const stub = parameters.get('stub');
        const variation = parameters.get('variation');
        const requestUrl = request.url;
        // do nothing if no parameter for stub
        if (!stub || !variation) {
            return next.handle(request);
        }

        if (!this.stubPageEndpointKeyMapping.hasOwnProperty(stub)) {
            return next.handle(request);
        }
        for (const endpoint in this.stubPageEndpointKeyMapping[stub]) {
            if (requestUrl.indexOf(endpoint) > 1) {
                const stubKey = this.stubPageEndpointKeyMapping[stub][endpoint];
                const u = new URL(requestUrl);
                const apiDomain = u.protocol + '//' + u.host;

                // replace request with mock endpoint
                const httpRequest = new HttpRequest('GET', apiDomain + `/mock/checkout/${stub}/${stubKey}`);
                let newParams = new HttpParams({fromString: request.params.toString()});
                newParams = newParams.append('variation', variation);
                request = Object.assign(request, httpRequest);

                const dupReq = request.clone({
                    params: newParams,
                });
                return next.handle(dupReq);
            }
        }
        return next.handle(request);
    }
}
