<announcement-placeholder
    class="comp-announcement-container"
    *ngIf="show_component"
    [endPointUrl]="app_config.submodule_api_url + '/announcements'"
    [placeholderCode]="'FOOTER_TOP'"
    [websiteCode]="app_config.website_code"
    [containerClass]="'footer-top'"
    [closeIconClass]="'fehp-close'"></announcement-placeholder>

<footer class="global-footer-container"  *ngIf="show_component">
    <newsletter *ngIf="hasNewsletter"></newsletter>
    <ng-template newsletter-modal-trigger></ng-template>
    <div class="footer-content-container container">
        <div class="row">
            <div class="col-md-6 hidden-sm hidden-xs logo-wrap">
                 <a href="{{app_config.site_url}}">
                    <img class="site-logo" title="48HourPrint.com" alt="48HourPrint.com" src="assets/img/48hp-logo.gif">
                </a>
            </div>
            <ng-container *ngIf="footer_nav">
                <div class="col-md-6 col-sm-24 footer-nav" *ngFor="let fNav of footer_nav | siteObjNgFor ">
                    <h4 class="title" tabindex="0">{{fNav.catalog_name}}</h4>
                    <ul class="footer-nav-links">
                        <li *ngFor="let fLinks of fNav.sorted_children | siteObjNgFor">
                            <a class="site-secondary-link" href="{{setFooterUrl(fLinks.url)}}" *ngIf="fLinks.url">{{fLinks.catalog_name}}</a>
                            <span *ngIf="!fLinks.url">{{fLinks.catalog_name}}</span>
                        </li>
                    </ul>
                </div>
            </ng-container>
            <div class="col-md-6 col-sm-24 footer-nav">
                <div class="social-media-links">
                    <h4 class="title" tabindex="0"> Connect with Us </h4>
                    <a class="social-fb" href="https://www.facebook.com/48HourPrint" target="_blank" data-qaid="facebook" aria-label="Facebook">
                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                    </a>
                    <a class="social-twitter" href="https://twitter.com/48hourprint" target="_blank" data-qaid="twitter" aria-label="Twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a class="social-instagram" href="https://www.instagram.com/48HourPrint" target="_blank" data-qaid="instagram" aria-label="Insta gram">
                        <i class="fehp-instagram" aria-hidden="true"></i>
                    </a>
                    <a class="social-linkedin" href="https://www.linkedin.com/company/48hourprint.com" target="_blank" data-qaid="linkedin" aria-label="Linkedin">
                        <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="mobile-footer-logo hidden-md hidden-lg">
            <a [href]="app_config.site_url">
                <img title="48HourPrint.com" alt="48HourPrint.com" src="assets/img/48hp-logo.gif" class="site-logo lazy-loaded">
            </a>
        </div>
        <div class="copyright-wrapper">
            <ul class="copyright-section-links is-client">
                <ng-container *ngIf="legalNavs">
                    <li *ngFor="let lLinks of legalNavs.sorted_children | siteObjNgFor">
                        <a class="site-secondary-link"
                            *ngIf="lLinks.url"
                            href="{{setFooterUrl(lLinks.url)}}"
                            target="{{lLinks.new_window_flag ? '_blank' : '_self'}}">{{lLinks.catalog_name}}</a>
                        <span *ngIf="!lLinks.url">{{lLinks.catalog_name}}</span>
                    </li>
                </ng-container>
                <li class="copyright-label-wrapper">
                    <a class="site-secondary-link" href="{{app_config.site_url}}/terms-of-use.html" data-qaid="copyright">
                        © 2003–{{current_year}} {{app_config.site_name}}.com All Rights Reserved
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>

<announcement-placeholder
    *ngIf="show_component"
    class="comp-announcement-container"
    [endPointUrl]="app_config.submodule_api_url + '/announcements'"
    [placeholderCode]="'FOOTER_BOTTOM'"
    [websiteCode]="app_config.website_code"
    [containerClass]="'footer-bottom'"
    [closeIconClass]="'fehp-close'"></announcement-placeholder>

<announcement-preview-tool [cookieDomain]="app_config.cookie_domain"></announcement-preview-tool>

