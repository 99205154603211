/**
 * Created by prince.g@digitalroominc.com on 01/17/2019.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DriCommonModule} from '@dri/common/common.module';
import {OrderSummaryComponent} from './components/order-summary.component';
import {OrderSummaryItemFilePreviewComponent} from './components/order-summary-item-file-preview.component';
import {CheckoutModule} from '@dri/checkout/checkout.module';
import {ShippingModule} from '@dri/shipping/shipping.module';
import {ItemLevelShippingSummaryComponent} from './components/item-level-shipping-summary.component';
import {OrderLevelShippingSummaryComponent} from './components/order-level-shipping-summary.component';
import {CouponStorageService} from './services/coupon-storage.service';
import {AppErrorMessagesComponent} from './components/app-error-messages.component';
import {AppTextAttributeComponent} from './components/app-text-attribute.component';
import {FormsModule} from '@angular/forms';
import {AppEddComponent} from './components/app-edd.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CustomDropdownLibrary} from '@dri/custom-dropdown-library';
import {CustomDropdownComponent} from './components/custom-dropdown.component';
import {AppShippingOptionsRadioComponent} from '../shipping/components/app-shipping-options-radio.component';
import {SecuredCheckoutTooltipComponent} from './components/secured-checkout-tooltip.component';
import {DriDesignModule} from '@dri/design/design.module';

@NgModule({
    imports: [
        CommonModule,
        DriCommonModule,
        CheckoutModule,
        ShippingModule,
        FormsModule,
        PopoverModule,
        DriDesignModule,
    ],
    exports: [
        OrderSummaryComponent,
        OrderSummaryItemFilePreviewComponent,
        ItemLevelShippingSummaryComponent,
        OrderLevelShippingSummaryComponent,
        AppErrorMessagesComponent,
        AppTextAttributeComponent,
        OrderLevelShippingSummaryComponent,
        AppEddComponent,
        CustomDropdownComponent,
        AppShippingOptionsRadioComponent,
        SecuredCheckoutTooltipComponent,
    ],
    declarations: [
        OrderSummaryComponent,
        OrderSummaryItemFilePreviewComponent,
        ItemLevelShippingSummaryComponent,
        OrderLevelShippingSummaryComponent,
        AppErrorMessagesComponent,
        AppTextAttributeComponent,
        OrderLevelShippingSummaryComponent,
        AppEddComponent,
        CustomDropdownComponent,
        AppShippingOptionsRadioComponent,
        SecuredCheckoutTooltipComponent,
    ],
    providers: [
        CouponStorageService,
        CustomDropdownLibrary,
    ],
})
export class AppCommonModule {
}
