import {Injectable, EventEmitter} from '@angular/core';
import {ConfigManagerService} from '@dri/common/services/config-manager.service';
import {LoginRegisterModalComponent} from '../components/login/login-register-modal.component';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Injectable()
export class LoginRegisterService {
    public is_login = false;
    public loginChange : EventEmitter<any> = new EventEmitter();
    public bsModalRef : BsModalRef;
    public params : any = {};
    public subscription : any;
    constructor(
        private ConfigManagerService: ConfigManagerService,
        private modalService: BsModalService
    ) {

    }

    public openModal(event:any = null) {
        this.params = event;

        let form_type = 'login';
        if (this.params && (typeof this.params.form_type != 'undefined' && this.params.form_type)) {
            form_type = this.params.form_type;
        }

        const modal_options = {
            initialState: {
                form_type: form_type,
            },
            class: 'comp-login-register-modal-container site-modal',
            backdrop: true,
            ignoreBackdropClick: true,
        };

        this.bsModalRef = this.modalService.show(LoginRegisterModalComponent, modal_options);

        if (event && typeof event.onModalClose == 'function') {
            this.subscription = this.modalService.onHide.subscribe((data) => {
                event.onModalClose();
                this.subscription.unsubscribe();
            });
        }
    }

    public setLoginDetails(params:any) {
        let event_data = params;
        if (this.params && typeof this.params == 'object') {
            event_data = Object.assign(event_data, this.params);
        }

        this.is_login = true;
        this.loginChange.emit(event_data);
        this.bsModalRef.hide();

        if (this.params && this.params.redirect_url) {
            window.location.href = this.params.redirect_url;
        }
    }

    public hideModal() {
        this.bsModalRef.hide();
    }
}
